import React, { useEffect, useState } from "react";
import { useFormikContext, Formik, Form, Field, useField } from "formik";

import {
  Grid,
  Card,
  Box,
  Stack,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  obtenerCuposSolicitud,
  obtenerDatosCupoAdicional,
  obtenerFlujo,
  obtenerInfoSolAprobada,
  obtenerMontoSugerido,
  obtenerPagosCliente,
} from "../services/creditoServices";

import "../styles/main.css";
import { DataGrid } from "@mui/x-data-grid";
import SCSAccordion from "app/modules/common/components/Accordions/SCSAccordion";
import {
  SCSTableData,
  SCSTableDataCell,
  SCSTableDataRow,
} from "app/modules/common/components/TableData/SCSTableData";
import InfoPopoverButton from "app/modules/shared/components/InfoPopoverButton";

const ComportamientoPago = ({ idCliente, cliente, cupoPreap, solic }) => {
  const [solicitud, setSolicitud] = useState(solic);

  const [infoPagos, setInfoPagos] = useState([]);
  const [infoSolicitudAprobada, setInfoSolicitudAprobada] = useState([]);
  const [cuposSolicitud, setCuposSolicitud] = useState([]);
  const [datosCupoAdicional, setDatosCupoAdicional] = useState([]);
  const [totalIngresos, setTotalIngresos] = useState();
  const tipoCupo = new Map();
  tipoCupo.set("CUPALIM", "Abastos");
  tipoCupo.set("NORMAL", "Mercancias Generales");

  useEffect(() => {
    console.log(solicitud);

    const getDatos = async () => {
      let infoSolicitud;
      try {
        const respPagos = await obtenerPagosCliente(solicitud.idCliente);
        if (respPagos.state) {
          if (respPagos.data == null) infoSolicitud = {};
          else {
            setInfoPagos(respPagos.data);
          }
        } else {
          console.log(respPagos.message);
        }
      } catch (_errors) {
        console.log(_errors);
      }

      const respFlujo = await obtenerFlujo(solicitud.idFlujo);
      //console.log(respFlujo);
      const idGrupo = respFlujo.data.idGrupo;

      var response = await obtenerMontoSugerido(
        solicitud.identificacion,
        solicitud.idLocal
      );
      setTotalIngresos(response.data.totalIngresos);

      var respDatosCupoAdicional = await obtenerDatosCupoAdicional(
        idGrupo,
        response.data.totalIngresos
      );
      if (respDatosCupoAdicional.state) {
        setDatosCupoAdicional(respDatosCupoAdicional.data);
        //console.log(respDatosCupoAdicional.data);
      } else {
        console.log(respDatosCupoAdicional.message);
      }

      let cuposCliente;
      try {
        const cuposSolicitud = await obtenerCuposSolicitud(
          solicitud.idSolicitud
        );
        //debugger;
        if (cuposSolicitud.state) {
          if (cuposSolicitud.data == null) {
            cuposCliente = {};
            setCuposSolicitud(null);
          } else {
            console.log(cuposSolicitud.data);
            cuposCliente = cuposSolicitud.data;
            setCuposSolicitud(cuposCliente);
          }
        } else {
          console.log(cuposSolicitud.message);
        }
      } catch (_errors) {
        console.log(_errors);
      }

      try {
        const infoAprobacion = await obtenerInfoSolAprobada(
          solicitud.idSolicitud
        );
        if (infoAprobacion.state) {
          if (infoAprobacion.data == null) infoSolicitud = {};
          else {
            console.log(infoAprobacion.data);

            infoSolicitud = infoAprobacion.data;
          }
        } else {
          console.log(infoAprobacion.message);
        }
      } catch (_errors) {
        console.log(_errors);
      }

      const res = {
        ...infoSolicitud,
      };

      setInfoSolicitudAprobada(res);
    };
    getDatos();
  }, []);

  const formatearFecha = (fecha) => {
    if (!fecha) {
      return "";
    }
    const fechaModificada = fecha.split("T");
    return fechaModificada[0];
  };

  // Tabla Pagos

  const columns = [
    {
      field: "numeroTransaccion",
      //hide: true,
      headerName: "Numero de Transaccion",
      width: 200,
      headerClassName: "header-grid-solicitud",
    },
    {
      field: "nombreLocal",
      headerName: "Nombre Local",
      headerClassName: "header-grid-solicitud",
      width: 200,
      editable: false,
    },
    {
      field: "valor",
      headerName: "Valor",
      headerClassName: "header-grid-solicitud",
      width: 160,
      editable: false,
    },
    {
      field: "fecha",
      headerName: "Fecha Pago",
      headerClassName: "header-grid-solicitud",
      width: 200,
      editable: false,
    },
  ];

  const rows = infoPagos;

  const columnsData = [
    { id: 1, name: "" },
    { id: 2, name: "Monto Aprobado" },
    { id: 3, name: "Monto Solicitado Aumento" },
    { id: 4, name: "Total Solicitado" },
  ];

  return (
    <>
      <SCSAccordion tituloCabecera="Comportamiento de Pago">
        <div className="accordion-container">
          <Grid container justifyContent="center" rowSpacing={3}>
            <Grid item lg={12}>
              <Card style={{ justifyContent: "center" }}>
                <Stack
                  direction={"row"}
                  spacing={5}
                  justifyContent="center"
                  alignItems={"center"}
                >
                  <Typography component="div" sx={{ width: 400 }}>
                    <Box sx={{ fontWeight: "bold", m: 1 }}>Fecha De corte</Box>
                    <Box sx={{ fontWeight: "regular", m: 1 }}>
                      {infoSolicitudAprobada.fechaCorte == 1
                        ? "15"
                        : "30" || "Sin informacion"}
                    </Box>
                  </Typography>
                  <Typography component="div" sx={{ width: 400 }}>
                    <Box sx={{ fontWeight: "bold", m: 1 }}>
                      Fecha Aprobacion Solicitud
                    </Box>
                    <Box sx={{ fontWeight: "regular", m: 1 }}>
                      {formatearFecha(infoSolicitudAprobada.fechaAprobacion) ||
                        "Sin informacion"}
                    </Box>
                  </Typography>
                </Stack>
                <SCSTableData columnsData={columnsData} heightTable={20}>
                  {cuposSolicitud.map((row) => (
                    <SCSTableDataRow key={row.tipoCupo}>
                      <SCSTableDataCell align="center">
                        <Box sx={{ fontWeight: "bold", m: 1 }}>
                          {`${tipoCupo.get(row.tipoCupo)}`}
                        </Box>
                      </SCSTableDataCell>
                      <SCSTableDataCell align="center">
                        $ {row.montoAprobado}
                      </SCSTableDataCell>
                      <SCSTableDataCell align="center">
                        $ {row.montoSolicitado - row.montoAprobado}
                      </SCSTableDataCell>
                      <SCSTableDataCell align="center">
                        {row.tipoCupo === "CUPALIM" ? (
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            ${row.montoSolicitado}
                            {datosCupoAdicional.length > 0 && (
                              <>
                                <div>
                                  <InfoPopoverButton>
                                    <Stack
                                      direction={"column"}
                                      spacing={2}
                                      justifyContent={"center"}
                                      alignItems={"center"}
                                      mt={2}
                                    >
                                      <Typography>
                                        Cupo calculado a aprobar.
                                      </Typography>
                                      <Table
                                        size="small"
                                        aria-label="simple table"
                                        sx={{ bgcolor: "white" }}
                                      >
                                        <TableHead
                                          sx={{ bgcolor: "text.disabled" }}
                                        >
                                          <TableRow>
                                            <TableCell align="center">
                                              Tipo de Cupo
                                            </TableCell>
                                            <TableCell align="center">
                                              Porcentaje
                                            </TableCell>
                                            <TableCell align="center">
                                              Cupo Adicional
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {datosCupoAdicional.map((item) => (
                                            <TableRow>
                                              <TableCell align="center">
                                                {tipoCupo.get(item.tipoCupo)}
                                              </TableCell>
                                              <TableCell align="center">
                                                {item.porcentajeCupo}%
                                              </TableCell>
                                              <TableCell align="center">
                                                $
                                                {(totalIngresos *
                                                  item.porcentajeCupo) /
                                                  100 >
                                                item.montoMaximo
                                                  ? item.montoMaximo
                                                  : (totalIngresos *
                                                      item.porcentajeCupo) /
                                                    100}
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </Stack>
                                  </InfoPopoverButton>
                                </div>
                              </>
                            )}
                          </Stack>
                        ) : (
                          `$ ${row.montoSolicitado}`
                        )}
                      </SCSTableDataCell>
                    </SCSTableDataRow>
                  ))}
                </SCSTableData>
              </Card>
            </Grid>
            <Grid item lg={12}>
              <h2>Ultimos pagos de Cliente</h2>
            </Grid>
            <Grid item lg={6}>
              <div>
                <Box sx={{ height: 400, width: "100%" }}>
                  <DataGrid
                    getRowId={(row) => row.numeroTransaccion}
                    rows={rows}
                    columns={columns}
                    //pageSize={10}
                    rowsPerPageOptions={[5]}
                    disableSelectionOnClick
                  />
                </Box>
              </div>
            </Grid>
          </Grid>
        </div>
      </SCSAccordion>
    </>
  );
};

export default ComportamientoPago;
