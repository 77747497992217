export const diccionarioExtenciones = (extension) => {
  const extensionesPosibles = {
    jpeg: ".jpg",
    jpg: ".jpg",
    png: ".png",
    pdf: ".pdf",
    csv: ".csv",
  };

  if (!extensionesPosibles[extension]) {
    return ".pdf";
  }

  return extensionesPosibles[extension];
};

export const diccionarioParentesco = (codigoPrentesco) => {
  const codigoParentesco = {
    ABU: "ABUELO(A)",
    AMI: "AMIGO",
    CDO: "CONCUÑADO",
    CN: "CUÑADO(A)",
    COY: "CÓNYUGE",
    HER: "HERMANO(A)",
    HIJ: "HIJO(A)",
    MAD: "MADRE",
    MTR: "MADRASTRA",
    NIE: "NIETO/A",
    NUE: "NUERA",
    OT: "OTRO",
    PAD: "PADRE",
    PRI: "PRIMO(A)",
    PTR: "PADRASTRO",
    SOB: "SOBRINO(A)",
    SUE: "SUEGRO(A)",
    TIO: "TÍO(A)",
    TIT: "TITULAR",
    YER: "YERNO",
    EMP: "EMPRESA",
  };

  return codigoParentesco[codigoPrentesco];
};

export const detalleTipoRequerimiento = (idTipoRequerimiento) => {
  const detalleRequerimiento = {
    1: "CERTIFICADO",
    2: "CANCELACION SEGURO",
    3: "DEVOLUCION PRODUCTOS",
    4: "PRECANCELACION",
    17: "CAMBIO ESTADO",
  };
  return detalleRequerimiento[idTipoRequerimiento];
};

export const idSubTipoRequerimiento = (idSubTipoRequerimiento) => {
  const detalleRequerimiento = {
    5: "ESTADO DEUDA",
    6: "SIN DEUDA",
    7: "ESPECIAL",
    8: "PARCIAL",
    9: "TOTAL",
    10: "PARCIAL",
    11: "TOTAL",
  };

  return detalleRequerimiento[idSubTipoRequerimiento];
};

export const idEstados = (idEstados) => {
  const detalleRequerimiento = {
    ESTREQSOL: "SOLICITADO",
    ESTREQPRO: "EN PROCESO",
    ESTREQRES: "RESUELTO",
    ESTREQPTE: "PENDIENTE SIN DOCUMENTOS",
    ESTREQDES: "DESESTIMADO",
  };

  return detalleRequerimiento[idEstados];
};

export const documentoTipoRequerimiento = (idTipoRequerimiento) => {
  const detalleTipoRequerimiento = {
    1: "CRT_CREDITO_",
    2: "CRT_CNLSEG_",
    3: "CRT_DEVPRO_",
    4: "CRT_PRECNC_",
  };

  return detalleTipoRequerimiento[idTipoRequerimiento];
};

export const diccionarioTipoDireccion = (tipoDireccion) => {
  const tiposDireccion = {
    DIRDOM: "DOMICILIO",
    DIRLAB: "LABORAL",
    DIROTR: "OTROS",
    DIRGES: "GESTIÓN ",
  };

  return tiposDireccion[tipoDireccion];
};

export const diccionarioGrupo = (IdGrupo) => {
  const respuesta = {
    1: "CORPORACION FAVORITA C.A.",
    2: "GRUPO REY",
    3: "TVENTAS",
    4: "SERVIMAX SERVICIOS GLOGALES",
  };

  return respuesta[IdGrupo];
};

export const diccionarioCodigoGrupo = (idGrupo) => {
  const codigoGrupo = {
    1: "CORPFAV",
    2: "GRPREY",
    3: "TVTAS",
    4: "SERVIMAX",
  };

  return codigoGrupo[idGrupo];
};

export const diccionarioCiclosCob = (idGrupo) => {
  const codigoGrupo = {
    1: "CLICLO 15",
    2: "CLICLO 30",
  };

  return codigoGrupo[idGrupo];
};

export const diccionarioOrdenCob = (idGrupo) => {
  const codigoGrupo = {
    true: "ASCENDENTE",
    false: "DESCENDENTE",
  };

  return codigoGrupo[idGrupo];
};

export const diccionarioRolesUsuario = (idGrupo) => {
  const codigoGrupo = {
    SISTEMAS: "SISTEMAS",
    AGENTECC: "CALLCENTER",
    CALLCENTER: "CALLCENTER",
  };

  return codigoGrupo[idGrupo];
};

export const TIPOS_NEGOCIACION = {
  PRECANCELACION_TOTAL: "PRETOT",
  PRECANCELACION_CONSUMO: "PREPAR",
  DEVOLUCION_TOTAL: "DEVTOT",
  DEVOLUCION_PARCIAL: "DEVPAR",
};

export const TIPOS_CONDONACION = {
  CONDONACION_DESG: "DESG",
  CONDONACION_EXON: "EXON",
};

export const diccionarioMotivoRetencion = (motivo) => {
  const detalleMotivo = {
    MTREQFIR: "CADUCIDAD FIRMA",
    MTREQRET01: "RETENC. COBRO PERMANENTE",
    MTREQRET02: "RETENC. COBRO SOBRE SALDO",
    MTREQRET03: "RETENC. CUPON SALUD",
    MTREQRET04: "RETENC. MES GRATIS",
    MTREQRET05: "RETENC. ACLARACION BENEF.",
    MTREQRET06: "RETENC. DEUDA PENDIENTE",
    MTREQRET11: "VENTA SIN INF./INF.BENEFICIOS",
    MTREQRET12: "CLNT. CONOCE SEG./INFO. BENEF.",
    MTREQRET13: "CLNT. CONOCE SEG./CTA. SEG/GRA",
    MTREQRET14: "LOC.IND CNC./INFO.BNF-CLNT.DEUD",
    MTREQRET15: "MALA ATEN.PRESTAD./INFO. BENF.",
    MTREQRET16: "TIENE OTRO SEG./INFO. BENEF.",
    MTREQRET17: "DESEA1SEG.CON >COB./INF.BENEF.",
    MTREQRET18: "ERROR COBR. TARJ/ACT.FORM.D.PAG",
    MTREQRET19: "DIFICULTAD. ECONOM./MES GRATIS",
    MTREQRET20: "MIGRA.NVA.COND.DELSEG/INF.BENF",
    MTREQRET21: "MIGRA.NVS.COND.SEG/CUPONSALUD",
    MTREQRET22: "RETENCION CLIENTE INUBICABLE",
    MTREQRET23: "DUPLICADO",
  };

  return detalleMotivo[motivo];
};

export const diccionarioIdentificacion = (codigoIdentificacion) => {
  const cogidoIdentificacion = {
    CED: "CEDULA",
    PAS: "PASAPORTE",
    RUC: "RUC",
  };

  return cogidoIdentificacion[codigoIdentificacion];
};

export const diccionarioTipoPago = (codigoTipoPago) => {
  const cogidoTipoPago = {
    PAGCITA00: "COPAGO 5",
    PAGCITA01: "COPAGO 10",
    PAGCITA02: "SIN COSTO",
    PAGCITA03: "PREFERENCIAL",
  };

  return cogidoTipoPago[codigoTipoPago];
};

export const diccionarioEspecialidad = (codigoEspecialidad) => {
  const cogidoEspecialidad = {
    1: "ALERGOLOGIA",
    2: "AUDIOLOGIA",
    3: "CARDIOLOGIA",
    4: "CIRUGIA CARDIOVASCULAR",
    5: "CIRUGIA GENERAL Y LAPAROSCOPIA",
    6: "NEUROCIRUGIA",
    7: "CIRUGIA ONCOLOGICA",
    8: "CIRUGIA PEDIATRICA",
    9: "CIRUGIA PLASTICA",
    10: "FLEBOLOGIA & CIRUGIA VASCULAR",
    11: "DERMATOLOGIA",
    12: "ENDOCRINOLOGIA",
    13: "FISIATRIA Y REHABILITACION",
    14: "ODONTOPEDIATRIA",
    15: "GASTROENTEROLOGIA",
    16: "GERIATRIA",
    17: "GINECOLOGIA",
    18: "TELEMEDICINA",
    19: "HEMATOLOGIA",
    20: "MAXILO FACIAL",
    21: "MEDICINA FAMILIAR",
    22: "MEDICINA GENERAL",
    23: "MEDICINA INTERNA",
    24: "MEDICINA OCUPACIONAL",
    25: "MEDICO INTENSIVISTA",
    26: "NEUMOLOGIA",
    27: "NEUROLOGIA",
    28: "NUTRICION",
    29: "NUTRICION Y DIETETICA",
    30: "ODONTOLOGIA",
    31: "OFTALMOLOGIA",
    32: "ONCOLOGIA CLINICA",
    33: "ORTOPEDIA",
    34: "OTORRINOLARINGOLOGIA",
    35: "PEDIATRIA",
    36: "PROCTOLOGIA",
    37: "PSICOLOGIA CLINICA",
    38: "PSIQUIATRIA",
    39: "REUMATOLOGIA",
    40: "TRAUMATOLOGIA",
    41: "UROLOGIA",
    42: "LABORATORIO",
    43: "NEFROLOGIA",
    44: "PODOLOGIA",
    45: "OPTOMETRIA",
    46: "AUDIOMETRIA",
    47: "IMAGENOLOGIA",
    48: "MASTOLOGIA",
  };

  return cogidoEspecialidad[codigoEspecialidad];
};

export const diccionarioRolUsuario = (codigoTipoPago) => {
  const cogidoTipoPago = {
    ADMINISTRADOR: "ADMINISTRADOR DE SISTEMA",
    SISTEMAS: "OFICIAL DE SISTEMAS",
    GERENTE: "GERENCIAL",
    OPERACIONES: "OPERACIONES",
    COMERCIAL: "JEFATURA COMERCIAL",
    CREDITO: "JEFATURA CREDITO",
    CALLCENTER: "JEFATURA CALLCENTER",
    RIESGOS: "ANALISTA DE RIESGOS",
    SINIESTROS: "SUPERVISOR SINIESTROS",
    SUPERVISORCC: "SUPERVISOR CALLCENTER",
    GESTORCBR: "GESTOR DE COBRANZAS",
    AGENTECC: "AGENTE DE CALL CENTER",
    ANALISTACRE: "ANALISTA DE CREDITO",
    VENDEDORCOM: "VENDEDOR COMERCIAL CALLCENTER",
    REVISORIACONTROL: "REVISORIA Y CONTROL",
    LOCALPILOTO: "LOCAL PILOTO CORPORACION FAVORITA",
    ASEGURADORA: "ZURICH ASEGURADORA",
    OFICINACF: "OFICINA CORPORACIÓN FAVORITA",
    ASISTENCIAEXT:
      "PROVEEDORES EXTERNOS DE  ASISTENCIA SALUD CORIS JARDINES DEL VALLE",
    AUDITORIACF: "AUDITORIA CORPORACIÓN FAVORITA",
    VENTAEXT: "FUERZA DE VENTA EXTERNA EN LOCAL",
    LOCAL: "LOCAL CORPORACION FAVORITA",
    AUTORIZADOR: "WEB SERVICES AUTORIZADOR",
    VENTAEXTSERVIMAX: "FUERZA DE VENTA EXTERNA EN LOCAL",
    CALLCENTERCF: "CALL CENTER CORPORACION FAVORITA",
    GENERICOFILIALES: "ROL GENERICO FILIALES",
    GESTION: "MODULO DE GESTION PRUEBAS",
    VENTAGBS: "FUERZA DE VENTA EXTERNA EN PROVICIONAL",
    ANALISTADATOS: "ANALISTA DE DATOS - BI",
    AGENTECALLBACKUP: "AGENTE CALL CENTER BACKUP",
    LOCALTVENTAS: "LOCAL TEVENTAS",
    CALLCENTERTV: "CALL CENTER TVENTAS ",
    MERCORP: "MERCANCIAS CORPORACION",
    PROSPECCION: "USUARIO PROSPECCION",
    GENERICOCLI: "USUARIO GENERICO CLIENTE",
    COMUNITYCC: "COMUNITY CALL CENTER",
    SUPERVISORSNT: "SUPERVISOR DE SINIESTROS",
    SUPERVISORTV: "SUPERVISOR TVENTAS",
    GESTIONCOMERCIAL: "GESTION COMERCIAL",
    TABLETCF: "TABLETS LOCALES CF",
  };

  return cogidoTipoPago[codigoTipoPago];
};

export const diccionarioTipoNCR = (codigoTipoNCR) => {
  const tiposNCR = {
    NCRABONO: "N/CR ABONO",
    NCRSDOFAV: "N/CR SALDO A FAVOR",
    NCRXPREC: "POR PRECANCELACION",
    NCRDEVIVA: "DEVOLUCION IVA",
    NCRDEVMOR: "DEVOLUCION MORA",
    NCRDEVTAR: "DEVOLUCION PAGO TARDIO",
    NCRDEVDES: "DEVOLUCION DESGRAVAMEN",
    NCRMALAPL: "PAGO MAL APLICADO",
  };

  return tiposNCR[codigoTipoNCR];
};

export const diccionarioTipoRubro = (codigoTipoRubro) => {
  const tiposRubro = {
    3: "INTERES",
    20: "IVA",
    24: "PAGO MAL APLICADO",
    22: "SEGURO DESGRAVAMEN",
    4: "INTERES DE MORA",
    5: "PAGO TARDIO SCS",
    34: "PAGO TARDIO SISCRE",
  };

  return tiposRubro[codigoTipoRubro];
};
