export const initialModalState = {
  title: "",
  type: "",
  openModal: false,
  mensaje: "",
};

export const modalNotificacionReducer = (modalState, modalDispatch) => {
  switch (modalDispatch.type) {
    case "SHOW_WARNING":
      return {
        ...modalState,
        title: modalDispatch.title,
        type: "warning",
        openModal: true,
        message: modalDispatch.message,
        urlRoute: true,
      };
    case "SHOW_ERROR":
      return {
        ...modalState,
        title: modalDispatch.title,
        type: "error",
        openModal: true,
        message: modalDispatch.message,
        urlRoute: true,
      };
    case "SHOW_INFO":
      return {
        ...modalState,
        title: modalDispatch.title,
        type: "info",
        openModal: true,
        message: modalDispatch.message,
        urlRoute: true,
      };
    case "SHOW_SUCCESS":
      return {
        ...modalState,
        title: modalDispatch.title,
        type: "success",
        openModal: true,
        message: modalDispatch.message,
        urlRoute: modalDispatch.urlRoute,
      };
    case "CLOSE_MODAL":
      return {
        ...modalState,
        title: "",
        type: "",
        openModal: false,
        message: "",
        urlRoute: true,
      };

    default:
      return modalState;
  }
};
