import React, { useEffect, useState } from "react";
import { useFormikContext, Formik, Form, Field, useField } from "formik";

import {
  Alert,
  Button,
  Grid,
  TextField,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import InputLabel from "@mui/material/InputLabel";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Select from "@mui/material/Select";

import {
  obtenerDetalleParametro,
  obtenerParametroGrupo,
} from "../../app-configuracion/services/configuracionServices";
import {
  consultaCatalogosConPadre,
  consultaCatalogosSinPadre,
} from "../../app-cliente/services/Catalogos/catalogosService";
import {
  obtenerMontoSugerido,
  grabarDatosAprobacion,
  grabarMotivoNoAprobacion,
  cambiarEstadoSolicitud,
  grabarVerificacion,
  grabarSolicitud,
  obtenerAumentoCupo,
  grabarAumentoCupo,
  obtenerFlujo,
  obtenerDatosCupoAdicional,
  grabarCupoAdicional,
  grabarCupoSolicitud,
  obtenerCuposSolicitud,
} from "../services/creditoServices";

import "../styles/main.css";
import { useSelector } from "react-redux";
import ModalAlertaNotificacionEstandar from "app/modules/app-servicios/components/ModalAlertaNotificacionEstandar";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import { LoadingButton } from "@mui/lab";
import { SCSGenericButton } from "app/modules/common/components/Buttons/SCSButtons";
import SCSAccordion from "app/modules/common/components/Accordions/SCSAccordion";
import CircleIcon from "@mui/icons-material/Circle";
import InfoPopoverButton from "app/modules/shared/components/InfoPopoverButton";

const FormCapacidadPago = ({ idCliente, cliente, cupoPreap, solic }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  const [idClienteForm, setIdClienteForm] = useState(null);
  const [clienteInfo, setClienteInfo] = useState(null);
  const [solicitud, setSolicitud] = useState(solic);

  const [perfilCF, setPerfilCF] = useState();
  const [scoreBuro, setScoreBuro] = useState();
  const [cuotasIfis, setCuotasIfis] = useState();
  const [din, setDin] = useState();
  const [dinCalculado, setDinCalculado] = useState(0);
  const [montoSuger, setMontoSuger] = useState();
  const [montoSolic, setMontoSolic] = useState(solic.montoSolicitado);
  const [idEstadoSolic, setIdEstadoSolic] = useState(0);
  const [califBuro, setCalifBuro] = useState();
  const [coeficienteDin, setCoeficienteDin] = useState();
  const [totalIngresos, setTotalIngresos] = useState();
  const [valorPresente, setValorPresente] = useState();
  const [tipoLocal, setTipoLocal] = useState("");
  const [colorLocal, setColorLocal] = useState("");
  const [datosCupoAdicional, setDatosCupoAdicional] = useState([]);

  const [enableMotivo, setEnableMotivo] = useState(false);
  const [comentario, setComentario] = useState("");
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);
  const [mensajeError, setMensajeError] = useState("");
  const [mensajeErrorCambioSolicitud, setMensajeErrorCambioSolicitud] =
    useState(false);

  const [montoMinimio, setMontoMinimo] = useState();
  const [montoMaximo, setMontoMaximo] = useState();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openConfirmar, setOpenConfirmar] = useState(false);
  const [grupoSolicitud, setGrupoSolicitud] = useState(null);
  const [cuposSolicitud, setCuposSolicitud] = useState([]);

  const tipoCupo = new Map();
  tipoCupo.set("CUPALIM", "Cupo de Abastos");

  useEffect(() => {
    console.log(solicitud);
    getDatos();
    getMontoMaximoGeneral();
  }, []);
  useEffect(() => {}, [montoSuger]);

  const getMontoMaximoGeneral = async (tipoCupo) => {
    try {
      const respFlujo = await obtenerFlujo(solic.idFlujo);
      //console.log(respFlujo);
      const idGrupo = respFlujo.data.idGrupo;
      // const response = await obtenerParametroGrupo("MTOMAXCRE", idGrupo);
      // if (response.state) {
      //   setMontoMinimo(response.data.valorDesde);
      //   setMontoMaximo(response.data.valorHasta);
      //   //console.log(response.data);
      // } else {
      //   console.log("Error al obtener monto maximo", response);
      // }
      const montosMaximos = await obtenerDetalleParametro("MTOMAXCRE");
      if (montosMaximos.state) {
        const montoMaxItem = montosMaximos.data.find(
          (item) =>
            item.referencia ===
            `${idGrupo}NORMAL`
        );
        if (montoMaxItem) {
          console.log(montoMaxItem);
          setMontoMinimo(montoMaxItem.valorDesde);
          setMontoMaximo(montoMaxItem.valorHasta);
        } else {
          console.log("Error al obtener monto maximo", montosMaximos);
        }
      } else {
        console.log("Error al obtener monto maximo", montosMaximos);
      }
    } catch (_errors) {
      console.log("Error al obtener monto maximo");
      console.log(_errors);
    }
  };

  const getDatos = async () => {
    try {
      const respFlujo = await obtenerFlujo(solic.idFlujo);
      //console.log(respFlujo);
      const idGrupo = respFlujo.data.idGrupo;
      const idLocal = solic.idLocal;
      setGrupoSolicitud(idGrupo);
      var response = await obtenerMontoSugerido(
        cliente.identificacion,
        idLocal
      );
      if (response.state) {
        //console.log(response.data);
        setPerfilCF(response.data.perfilCF);
        setScoreBuro(response.data.score);
        setCuotasIfis(response.data.cuotaIfis);
        setDin(response.data.porcPerfil);
        setCalifBuro(response.data.califBuro);
        setCoeficienteDin(response.data.coeficienteDin);
        setTotalIngresos(response.data.totalIngresos);
        setTipoLocal(response.data.tipoLocal);
        setValorPresente(response.data.valorPresente);
        if (response.data.valor < 0) setMontoSuger(0);
        else setMontoSuger(response.data.valor);
        //console.log(response.data.valor);

        switch (response.data.tipoLocal) {
          case "BUENO":
            setColorLocal("success");
            break;
          case "MALO":
            setColorLocal("error");
            break;
          default:
            setColorLocal("warning");
            break;
        }
      } else {
        console.log(response.message);
      }

      debugger;
      if (solic.aumentoCupo) {
        let cuposCliente;
        try {
          const cuposSolicitud = await obtenerCuposSolicitud(
            solicitud.idSolicitud
          );
          //debugger;
          if (cuposSolicitud.state) {
            if (cuposSolicitud.data == null) {
              cuposCliente = {};
              setCuposSolicitud(null);
            } else {
              console.log(cuposSolicitud.data);
              cuposCliente = cuposSolicitud.data;
              setCuposSolicitud(cuposCliente);
            }
          } else {
            console.log(cuposSolicitud.message);
          }
        } catch (_errors) {
          console.log(_errors);
        }

        var respDatosCupoAdicional = await obtenerDatosCupoAdicional(
          idGrupo,
          response.data.totalIngresos
        );
        if (respDatosCupoAdicional.state) {
          let nuevosMontos = cuposCliente.reduce((acc, cupo) => {
            console.log(cupo.tipoCupo);
            const itemCupo = respDatosCupoAdicional.data.find(
              (item) => item.tipoCupo === `${cupo.tipoCupo}`
            );
            if (itemCupo) {
              console.log(cupo);
              console.log(itemCupo);

              const cupoConValoresAdicionales = {
                ...cupo,
                porcentajeNuevo: itemCupo.porcentajeCupo, // reemplaza "valor1" con el valor que quieras
                mtomax: itemCupo.montoMaximo, // reemplaza "valor2" con el valor que quieras
              };
              acc.push(cupoConValoresAdicionales);
            } else {
              acc.push(cupo);
            }
            return acc; // Si no hay coincidencia, devuelve el acumulador sin cambios
          }, []);

          setCuposSolicitud(nuevosMontos);
          setDatosCupoAdicional(respDatosCupoAdicional.data);
          //console.log(respDatosCupoAdicional.data);
        } else {
          console.log(respDatosCupoAdicional.message);
        }
      } else {
        var respDatosCupoAdicional = await obtenerDatosCupoAdicional(
          idGrupo,
          response.data.totalIngresos
        );
        if (respDatosCupoAdicional.state) {
          console.log(respDatosCupoAdicional.data);
          setDatosCupoAdicional(respDatosCupoAdicional.data);
        } else {
          console.log(respDatosCupoAdicional.message);
        }
      }
    } catch (_errors) {
      console.log(_errors);
    }
  };

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
          habilitarMotivo(e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const SelectItemsCatalogoSinPadre = ({
    pais,
    especial,
    catalogos,
    grupo,
    name,
    labelName,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      if (pais === "ECU") {
        const getData = async () => {
          const prov = await consultaCatalogosSinPadre(
            especial,
            catalogos,
            grupo
          );

          setProvincias(prov.data);
        };
        getData();
      }
    }, [pais]);

    return (
      <FormControl sx={{ minWidth: 150 }} required>
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {pais === "ECU" ? (
            provincias.map((provincia, index) => (
              <MenuItem key={index} value={provincia.codigo}>
                {provincia.descripcion}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="">none</MenuItem>
          )}
        </Field>
      </FormControl>
    );
  };

  const SelectItemsCatalogoConPadre = ({
    pais,
    especial,
    catalogos,
    codPadre,
    grupo,
    name,
    labelName,
    componentSize,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      if (pais === "ECU") {
        const getData = async () => {
          const prov = await consultaCatalogosConPadre(
            especial,
            catalogos,
            codPadre,
            grupo
          );

          setProvincias(prov.data);
        };
        getData();
      }
    }, [pais, codPadre]);

    return (
      <FormControl disabled={!enableMotivo} sx={{ width: `${componentSize}` }}>
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {pais === "ECU" ? (
            provincias.map((provincia, index) => (
              <MenuItem key={index} value={provincia.codigo}>
                {provincia.descripcion}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="">none</MenuItem>
          )}
        </Field>
      </FormControl>
    );
  };

  const configuracionDatosEnvio = async (values) => {
    setLoading(true);
    const today = new Date().toLocaleString("en-US");
    if (values.estadoSolicitud === "SOLIC03") {
      if (dinCalculado !== 0) {
        debugger;
        // Aprobada

        if (solicitud.aumentoCupo) {
          try {
            var resp = await cambiarEstadoSolicitud(
              solicitud.idSolicitud,
              11,
              authUser.key,
              authUser.estacion
            );
            if (resp.state) {
              let aumentCupo;
              var respDatosAumentoCupo = await obtenerAumentoCupo(
                solicitud.idSolicitud
              );
              if (respDatosAumentoCupo.state) {
                aumentCupo = respDatosAumentoCupo.data;
              } else {
                console.log(respDatosAumentoCupo.message);
              }
              try {
                aumentCupo.idEstado = 11;
                aumentCupo.montoAprobado = values.montoOtorgado;
                console.log(aumentCupo);
                var respgrabarAumentoCupo = await grabarAumentoCupo(aumentCupo);
                //console.log(respgrabarAumentoCupo);
              } catch (error) {
                console.log(error);
              }

              cuposSolicitud.map(async (cupo) => {
                debugger;
                if (cupo.tipoCupo === "NORMAL") {
                  cupo.montoAprobado = values.montoOtorgado;
                  cupo.porcentaje = Math.trunc(din * 100);
                  cupo.valorBase = valorPresente;
                  cupo.fechaModificacion = today;
                  cupo.usuarioModificacion = authUser.key;
                  cupo.estacionModificacion = authUser.estacion;
                } else {
                  cupo.montoAprobado =
                    (totalIngresos * cupo.porcentajeNuevo) / 100 <
                    cupo.montoAprobado
                      ? cupo.montoAprobado
                      : (totalIngresos * cupo.porcentajeNuevo) / 100;
                  cupo.porcentaje = cupo.porcentajeNuevo;
                  cupo.valorBase = totalIngresos;
                  cupo.fechaModificacion = today;
                  cupo.usuarioModificacion = authUser.key;
                  cupo.estacionModificacion = authUser.estacion;
                }
                debugger;
                try {
                  console.log("Envio de Cupo actualizado a base");

                  await grabarCupoSolicitud(cupo);
                  setDatosEnviadosCorrectamente(true);

                  setTimeout(() => {
                    setDatosEnviadosCorrectamente(false);
                  }, 3000);
                } catch (error) {
                  console.log(error);
                }
              });

              const infoAprobacion = {
                idSolicitud: solicitud.idSolicitud,
                perfilCF: perfilCF,
                score: scoreBuro,
                politica: Math.trunc(din * 100),
                calculado: (dinCalculado * 100).toFixed(2),
                cuotaMensual: cuotasIfis || 0.0,
                cuotaSistemaFinanciero: cuotasIfis,
                calificacion: califBuro,
                montoCalculado: montoSuger,
                montoAprobado: values.montoOtorgado,
                esActivo: true,
                aumentoCupo: solicitud.aumentoCupo,
                fechaAprobacion: today,
                fechaCreacion: today,
                usuarioCreacion: authUser.key,
                estacionCreacion: authUser.estacion,
                fechaModificacion: today,
                usuarioModificacion: authUser.key,
                estacionModificacion: authUser.estacion,
              };
              console.log(infoAprobacion);
              try {
                solicitud.montoAprobado = values.montoOtorgado;
                solicitud.secuencial = 2;
                solicitud.idEstado = 11;
                const respuestaGrabarSol = await grabarSolicitud(solicitud);
              } catch (error) {
                console.log(error);
              }

              try {
                const respuestaDatosAprobacion = await grabarDatosAprobacion(
                  infoAprobacion
                );
                setOpen(true);
              } catch (error) {
                console.log(error);
              }

              var datos = {
                idSolicitud: solicitud.idSolicitud,
                tipo: "Aprobada",
                observacion: values.comentario,
                esActivo: true,
                fechaCreacion: today,
                usuarioCreacion: authUser.key,
                estacionCreacion: authUser.estacion,
                fechaModificacion: today,
                usuarioModificacion: authUser.key,
                estacionModificacion: authUser.estacion,
              };
              console.log("Grabar comentario de Aprobacion");

              const respuestaVerificacion = grabarVerificacion(datos)
                .then((response) => {
                  console.log(response.data);
                })
                .catch((e) => {
                  console.log(e);
                });
            } else {
              setMensajeError(resp.message);
              setMensajeErrorCambioSolicitud(true);

              setTimeout(() => {
                setMensajeErrorCambioSolicitud(false);
              }, 2000);
            }
          } catch (e) {
            console.log(e);
          }
        } else {
          if (solicitud.idFlujo === 2) {
            try {
              var resp = await cambiarEstadoSolicitud(
                solicitud.idSolicitud,
                6,
                authUser.key,
                authUser.estacion
              );
              if (resp.state) {
                const infoAprobacion = {
                  idSolicitud: solicitud.idSolicitud,
                  perfilCF,
                  score: scoreBuro,
                  politica: Math.trunc(din * 100),
                  calculado: (dinCalculado * 100).toFixed(2),
                  cuotaMensual: cuotasIfis || 0.0,
                  cuotaSistemaFinanciero: cuotasIfis,
                  calificacion: califBuro,
                  montoCalculado: montoSuger,
                  montoAprobado: values.montoOtorgado,
                  esActivo: true,
                  aumentoCupo: solicitud.aumentoCupo,
                  fechaAprobacion: today,
                  fechaCreacion: today,
                  usuarioCreacion: authUser.key,
                  estacionCreacion: authUser.estacion,
                  fechaModificacion: today,
                  usuarioModificacion: authUser.key,
                  estacionModificacion: authUser.estacion,
                };
                console.log(infoAprobacion);
                try {
                  solicitud.montoAprobado = values.montoOtorgado;
                  solicitud.secuencial = 2;
                  solicitud.idEstado = 6;
                  const respuestaGrabarSol = await grabarSolicitud(solicitud);
                } catch (error) {
                  console.log(error);
                }

                try {
                  const respuestaDatosAprobacion = await grabarDatosAprobacion(
                    infoAprobacion
                  );

                  setOpen(true);
                } catch (error) {
                  console.log(error);
                }
              } else {
                setMensajeError(resp.message);
                setMensajeErrorCambioSolicitud(true);

                setTimeout(() => {
                  setMensajeErrorCambioSolicitud(false);
                }, 2000);
              }
            } catch (e) {
              console.log(e);
            }
          } else {
            try {
              var resp = await cambiarEstadoSolicitud(
                solicitud.idSolicitud,
                11,
                authUser.key,
                authUser.estacion
              );
              if (resp.state) {
                debugger;
                if (datosCupoAdicional.length > 0) {
                  datosCupoAdicional.forEach(async (cupo) => {
                    let cupoAdicionales = {
                      idSolicitud: solicitud.idSolicitud,
                      tipoCupo: cupo.tipoCupo,
                      montoAprobado:
                        (totalIngresos * cupo.porcentajeCupo) / 100 >
                        cupo.montoMaximo
                          ? cupo.montoMaximo
                          : (totalIngresos * cupo.porcentajeCupo) / 100,
                      porcentaje: cupo.porcentajeCupo,
                      valorBase: totalIngresos,
                      aumentoCupo: solicitud.aumentoCupo,
                      aprobadoAnterior: 0,
                      montoSolicitado: 0,
                      esActivo: true,
                      fechaCreacion: today,
                      usuarioCreacion: authUser.key,
                      estacionCreacion: authUser.estacion,
                      fechaModificacion: today,
                      usuarioModificacion: authUser.key,
                      estacionModificacion: authUser.estacion,
                    };
                    debugger;
                    try {
                      const respGrabarCupoAdicionales =
                        await grabarCupoAdicional(cupoAdicionales);
                    } catch (e) {
                      console.log(e);
                    }
                  });
                }

                let cupoAdicional = {
                  idSolicitud: solicitud.idSolicitud,
                  tipoCupo: "NORMAL",
                  montoAprobado: values.montoOtorgado,
                  porcentaje: Math.trunc(din * 100),
                  valorBase: valorPresente,
                  aumentoCupo: false,
                  aprobadoAnterior: 0,
                  montoSolicitado: solicitud.montoSolicitado,
                  esActivo: true,
                  fechaCreacion: today,
                  usuarioCreacion: authUser.key,
                  estacionCreacion: authUser.estacion,
                  fechaModificacion: today,
                  usuarioModificacion: authUser.key,
                  estacionModificacion: authUser.estacion,
                };
                try {
                  const respGrabarCupoAdicional = await grabarCupoAdicional(
                    cupoAdicional
                  );
                } catch (e) {
                  console.log(e);
                }

                const infoAprobacion = {
                  idSolicitud: solicitud.idSolicitud,
                  perfilCF: perfilCF,
                  score: scoreBuro,
                  politica: Math.trunc(din * 100),
                  calculado: (dinCalculado * 100).toFixed(2),
                  cuotaMensual: cuotasIfis || 0.0,
                  cuotaSistemaFinanciero: cuotasIfis,
                  calificacion: califBuro,
                  montoCalculado: montoSuger,
                  montoAprobado: values.montoOtorgado,
                  esActivo: true,
                  aumentoCupo: solicitud.aumentoCupo,
                  fechaAprobacion: today,
                  fechaCreacion: today,
                  usuarioCreacion: authUser.key,
                  estacionCreacion: authUser.estacion,
                  fechaModificacion: today,
                  usuarioModificacion: authUser.key,
                  estacionModificacion: authUser.estacion,
                };
                console.log(infoAprobacion);
                try {
                  solicitud.montoAprobado = values.montoOtorgado;
                  solicitud.secuencial = 2;
                  solicitud.idEstado = 11;
                  const respuestaGrabarSol = await grabarSolicitud(solicitud);
                } catch (error) {
                  console.log(error);
                }

                try {
                  const respuestaDatosAprobacion = await grabarDatosAprobacion(
                    infoAprobacion
                  );
                  setOpen(true);
                } catch (error) {
                  console.log(error);
                }

                var datos = {
                  idSolicitud: solicitud.idSolicitud,
                  tipo: "Aprobada",
                  observacion: values.comentario,
                  esActivo: true,
                  fechaCreacion: today,
                  usuarioCreacion: authUser.key,
                  estacionCreacion: authUser.estacion,
                  fechaModificacion: today,
                  usuarioModificacion: authUser.key,
                  estacionModificacion: authUser.estacion,
                };
                console.log("Grabar comentario de Aprobacion");

                const respuestaVerificacion = grabarVerificacion(datos)
                  .then((response) => {
                    console.log(response.data);
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              } else {
                setMensajeError(resp.message);
                setMensajeErrorCambioSolicitud(true);
                setLoading(false);

                setTimeout(() => {
                  setMensajeErrorCambioSolicitud(false);
                }, 3000);
              }
            } catch (e) {
              setMensajeError(e.message);
              setMensajeErrorCambioSolicitud(true);
              setLoading(false);

              setTimeout(() => {
                setMensajeErrorCambioSolicitud(false);
              }, 3000);
              setLoading(false);
            }
          }
        }
      } else {
        setMensajeError("Debe calcular el DIN antes de aprobar.");
        setMensajeErrorCambioSolicitud(true);

        setTimeout(() => {
          setMensajeErrorCambioSolicitud(false);
          setLoading(false);
        }, 2000);
      }
    }
    if (values.estadoSolicitud === "SOLIC02") {
      // Negada
      if (solicitud.aumentoCupo) {
        var resp = await cambiarEstadoSolicitud(
          solicitud.idSolicitud,
          10,
          authUser.key,
          authUser.estacion
        );
        if (resp.state) {
          let aumentCupo;
          var respDatosAumentoCupo = await obtenerAumentoCupo(
            solicitud.idSolicitud
          );
          if (respDatosAumentoCupo.state) {
            aumentCupo = respDatosAumentoCupo.data;
          } else {
            console.log(respDatosAumentoCupo.message);
          }

          try {
            aumentCupo.idEstado = 10;
            console.log(aumentCupo);
            var respgrabarAumentoCupo = await grabarAumentoCupo(aumentCupo);
            console.log(respgrabarAumentoCupo);
          } catch (error) {
            console.log(error);
          }

          try {
            solicitud.idEstado = aumentCupo.idEstadoOri;
            solicitud.idVendedor = aumentCupo.idVendedorOri;
            solicitud.idLocal = aumentCupo.idLocalOri;
            solicitud.fechaSolicitud = aumentCupo.fechaSolicitudOri;
            solicitud.montoSolicitado = aumentCupo.montoSolicitadoOri;
            solicitud.montoAprobado = aumentCupo.montoAprobadoOri;
            solicitud.ingresoFijoMensual = aumentCupo.ingresoFijoMensualOri;

            const respuestaGrabarSol = await grabarSolicitud(solicitud);
            console.log(respuestaGrabarSol);
          } catch (error) {
            console.log(error);
          }
          //
          const infoNegacion = {
            idSolicitud: solicitud.idSolicitud,
            motivo: values.motivo,
            submotivo: values.submotivo,
            comentario: values.comentario,
            estado: values.estadoSolicitud,
            fecha: today,
            estacion: authUser.estacion,
            usuario: authUser.key,
          };
          try {
            const respuestaMotivoNoAprobacion = await grabarMotivoNoAprobacion(
              infoNegacion
            );

            console.log(respuestaMotivoNoAprobacion);
            setOpen(true);
            // setDatosEnviadosCorrectamente(true);

            // setTimeout(() => {
            //   setDatosEnviadosCorrectamente(false);
            // }, 5000);
          } catch (error) {
            console.log(error);
          }
        } else {
          setMensajeError(resp.message);
          setMensajeErrorCambioSolicitud(true);

          setTimeout(() => {
            setMensajeErrorCambioSolicitud(false);
          }, 2000);
        }
      } else {
        try {
          var resp = await cambiarEstadoSolicitud(
            solicitud.idSolicitud,
            10,
            authUser.key,
            authUser.estacion
          );
          if (resp.state) {
            const infoNegacion = {
              idSolicitud: solicitud.idSolicitud,
              motivo: values.motivo,
              submotivo: values.submotivo,
              comentario: values.comentario,
              estado: values.estadoSolicitud,
              fecha: today,
              estacion: authUser.estacion,
              usuario: authUser.key,
            };
            try {
              const respuestaMotivoNoAprobacion =
                grabarMotivoNoAprobacion(infoNegacion);

              console.log(respuestaMotivoNoAprobacion);
              setOpen(true);
              // setDatosEnviadosCorrectamente(true);

              // setTimeout(() => {
              //   setDatosEnviadosCorrectamente(false);
              // }, 5000);
            } catch (error) {
              console.log(error);
            }
          } else {
            setMensajeError(resp.message);
            setMensajeErrorCambioSolicitud(true);

            setTimeout(() => {
              setMensajeErrorCambioSolicitud(false);
            }, 2000);
          }
        } catch (e) {
          setMensajeError(e.message);
          setMensajeErrorCambioSolicitud(true);

          setTimeout(() => {
            setMensajeErrorCambioSolicitud(false);
          }, 2000);
        }
      }
    }
    if (values.estadoSolicitud === "SOLIC01") {
      // devuelta
      if (solicitud.aumentoCupo) {
        setMensajeError("No puede devolver uns solicitud de Aumento de monto.");
        setMensajeErrorCambioSolicitud(true);

        setTimeout(() => {
          setMensajeErrorCambioSolicitud(false);
          setLoading(false);
        }, 2000);
      } else {
        try {
          var resp = await cambiarEstadoSolicitud(
            solicitud.idSolicitud,
            9,
            authUser.key,
            authUser.estacion
          );
          if (resp.state) {
            const infoNegacion = {
              idSolicitud: solicitud.idSolicitud,
              motivo: values.motivo,
              submotivo: values.submotivo,
              comentario: values.comentario,
              estado: values.estadoSolicitud,
              fecha: today,
              estacion: authUser.estacion,
              usuario: authUser.key,
            };
            try {
              const respuestaMotivoNoAprobacion =
                grabarMotivoNoAprobacion(infoNegacion);

              console.log(respuestaMotivoNoAprobacion);
              setOpen(true);
              // setDatosEnviadosCorrectamente(true);

              // setTimeout(() => {
              //   setDatosEnviadosCorrectamente(false);
              // }, 5000);
            } catch (error) {
              console.log(error);
            }
          } else {
            setMensajeError(resp.message);
            setMensajeErrorCambioSolicitud(true);

            setTimeout(() => {
              setMensajeErrorCambioSolicitud(false);
            }, 2000);
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  const habilitarMotivo = (value) => {
    if (value === "SOLIC03") {
      setIdEstadoSolic(11);
      setEnableMotivo(false);
    } else {
      if (value === "SOLIC02") {
        setIdEstadoSolic(10);
      } else {
        setIdEstadoSolic(9);
      }

      setEnableMotivo(true);
    }
  };

  const grabarSinVT = async (event) => {
    if (event.target.checked) {
      try {
        var resp = await cambiarEstadoSolicitud(
          solicitud.idSolicitud,
          5,
          authUser.key,
          authUser.estacion
        );
        if (resp.state) {
          const today = new Date().toLocaleString("en-US");
          var datos = {
            idSolicitud: solicitud.idSolicitud,
            tipo: "Sin VT",
            observacion: "Solicitud sin Verificacion Telefonica.",
            esActivo: true,
            fechaCreacion: today,
            usuarioCreacion: authUser.key,
            estacionCreacion: authUser.estacion,
            fechaModificacion: today,
            usuarioModificacion: authUser.key,
            estacionModificacion: authUser.estacion,
          };
          console.log("Grabar observacion de SIN VT");

          const respuestaVerificacion = grabarVerificacion(datos)
            .then((response) => {
              console.log(response.data);
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          console.log(resp);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const recalcularDin = async (monto) => {
    console.log(monto);
    const nuevoDin = (monto * coeficienteDin + cuotasIfis) / totalIngresos;
    console.log(nuevoDin);

    setDinCalculado(nuevoDin.toFixed(4));
  };

  const handleCloseConfirmar = () => {
    setOpenConfirmar(false);
  };
  return (
    <>
      <SCSAccordion tituloCabecera="Capacidad de pago y Aprobación">
        {montoSuger >= 0 ? (
          <Formik
            initialValues={{
              montoOtorgado: montoSuger.toFixed(0),
              comentario,
              motivo: "",
              submotivo: "",
            }}
            onSubmit={(values, { setSubmitting }) => {
              //console.log(values);
              //console.log(cuposSolicitud);
              configuracionDatosEnvio(values);
            }}
          >
            {({ values, handleChange }) => (
              <Form id="capacidadPago">
                <div className="accordion-container">
                  <Grid container justifyContent="center" rowSpacing={3}>
                    <Grid item lg={12}>
                      <Card style={{ justifyContent: "center" }}>
                        <header className="header-client-information">
                          <div>
                            <h4>Perfil CF</h4>
                            <h4>{perfilCF || "Sin informacion"}</h4>
                          </div>
                          <div>
                            <h4>Score Buró</h4>
                            <h4>{scoreBuro}</h4>
                          </div>
                          <div>
                            <h4>Cuotas IFIS</h4>
                            <h4>${cuotasIfis}</h4>
                          </div>
                          <div>
                            <h4>Monto Sugerido</h4>
                            <h4>${montoSuger}</h4>
                          </div>
                          <div>
                            <h4>DIN</h4>
                            <h4>{Math.trunc(din * 100)} %</h4>
                          </div>
                          <div>
                            <h4>DIN Calculado</h4>
                            <h4>{(dinCalculado * 100).toFixed(2)} %</h4>
                          </div>
                        </header>
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item lg={7}>
                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              justifyContent={"center"}
                              spacing={5}
                            >
                              <div>
                                <h4>Tipo de Local</h4>
                                <h4>{tipoLocal} </h4>
                              </div>
                              <CircleIcon fontSize="large" color={colorLocal} />
                              {datosCupoAdicional.length > 0 && (
                                <>
                                  <div>
                                    Cupo(s) Adicional(es)
                                    <InfoPopoverButton>
                                      <Table
                                        size="small"
                                        aria-label="simple table"
                                        sx={{ bgcolor: "white" }}
                                      >
                                        <TableHead
                                          sx={{ bgcolor: "text.disabled" }}
                                        >
                                          <TableRow>
                                            <TableCell align="center">
                                              Tipo de Cupo
                                            </TableCell>
                                            <TableCell align="center">
                                              Porcentaje
                                            </TableCell>
                                            <TableCell align="center">
                                              Cupo Adicional
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {datosCupoAdicional.map((row) => (
                                            <TableRow>
                                              <TableCell align="center">
                                                {tipoCupo.get(row.tipoCupo)}
                                              </TableCell>
                                              <TableCell align="center">
                                                {row.porcentajeCupo}%
                                              </TableCell>
                                              <TableCell align="center">
                                                $
                                                {(totalIngresos *
                                                  row.porcentajeCupo) /
                                                  100 >
                                                row.montoMaximo
                                                  ? row.montoMaximo
                                                  : (totalIngresos *
                                                      row.porcentajeCupo) /
                                                    100}
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </InfoPopoverButton>
                                  </div>
                                </>
                              )}
                            </Stack>
                          </Grid>
                          <Grid item lg={3}>
                            <Alert color="warning" severity="warning">
                              Calcular DIN previo a la aprobacion.
                            </Alert>
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Button
                              variant="contained"
                              onClick={() => {
                                recalcularDin(values.montoOtorgado);
                              }}
                            >
                              Calcular DIN
                            </Button>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                    <Grid item lg={12}>
                      <Grid container rowSpacing={4}>
                        <Grid item lg={2}>
                          <TextField
                            required={!enableMotivo}
                            id="montoOtorgado"
                            name="montoOtorgado"
                            type="number"
                            label="Monto Otorgado"
                            sx={{ width: "18ch" }}
                            value={values.montoOtorgado}
                            onChange={handleChange}
                            size="small"
                            InputProps={{
                              inputProps: {
                                min: montoMinimio,
                                max: montoMaximo,
                              },
                            }}
                          />
                        </Grid>
                        <Grid item lg={2}>
                          <SelectItemsCatalogoSinPadre
                            pais="ECU"
                            especial="false"
                            catalogos="ESTSOLIC"
                            grupo={1}
                            name="estadoSolicitud"
                            labelName="Resolucion"
                          />
                        </Grid>

                        <Grid item lg={3} disabled={!enableMotivo}>
                          <SelectItemsCatalogoConPadre
                            pais="ECU"
                            especial="false"
                            catalogos="MOTSOLIC"
                            codPadre={values.estadoSolicitud}
                            grupo={1}
                            name="motivo"
                            labelName="Motivo"
                            componentSize="80%"
                          />
                        </Grid>
                        <Grid item lg={3} disabled={!enableMotivo}>
                          <SelectItemsCatalogoConPadre
                            pais="ECU"
                            especial="false"
                            catalogos="SUBSOLIC"
                            codPadre={values.motivo}
                            grupo={1}
                            name="submotivo"
                            labelName="Submotivo"
                            componentSize="80%"
                          />
                        </Grid>
                        <Grid item xl={2}>
                          <FormGroup>
                            <FormControlLabel
                              name="desgravamen"
                              control={<Checkbox />}
                              label="Sin Verificacion Telefónica"
                              onChange={grabarSinVT}
                            />
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={12}>
                      <Grid container rowSpacing={4}>
                        <Grid item lg={6}>
                          <TextField
                            id="comentario"
                            name="comentario"
                            type="text"
                            label="Comentario "
                            sx={{ width: "150ch" }}
                            value={values.comentario}
                            onChange={handleChange}
                            size="small"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xl={4}>
                      <SCSGenericButton
                        //sx={{ width: "25ch" }}
                        //type="submit"
                        loading={loading}
                        loadingPosition="end"
                        variant="contained"
                        onClick={() => {
                          setOpenConfirmar(true);
                        }}
                        size={35}
                        titulo={"Guardar"}
                      />
                    </Grid>
                  </Grid>
                  <Dialog
                    onClose={handleCloseConfirmar}
                    aria-labelledby="customized-dialog-title"
                    open={openConfirmar}
                    fullWidth
                  >
                    <DialogTitle
                      id="customized-dialog-title"
                      onClose={handleCloseConfirmar}
                    >
                      Procesar Solicitud
                    </DialogTitle>
                    <DialogContent dividers>
                      <Typography variant="h2">
                        ¿Esta seguro de procesar la solicitud actual?.
                      </Typography>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        autoFocus
                        variant="contained"
                        type="submit"
                        form="capacidadPago"
                        onClick={handleCloseConfirmar}
                      >
                        Si
                      </Button>
                      <Button
                        autoFocus
                        color="error"
                        onClick={handleCloseConfirmar}
                        variant="outlined"
                      >
                        No
                      </Button>
                    </DialogActions>
                  </Dialog>
                  {datosEnviadosCorrectamente && (
                    <Alert severity="success" variant="filled">
                      Los datos han sido guardados correctamente.
                    </Alert>
                  )}
                  {mensajeErrorCambioSolicitud && (
                    <Alert severity="error" variant="filled">
                      {mensajeError}
                    </Alert>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <SpinnerLoadSrx />
        )}
      </SCSAccordion>
      <ModalAlertaNotificacionEstandar
        open={open}
        setOpen={setOpen}
        titulo="Solicitud de credito"
        mensaje="Solicitud de credito procesada!!"
      />
    </>
  );
};

export default FormCapacidadPago;
