import React, { useEffect, useState } from "react";

import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import { consultarPDFEstado } from "../services/transaccionalService";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    minWidth: "70%",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    /* width: "50%", */
  },
  /* "& .MuiDialogActions-root": {
      with: 30,
    }, */
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const VisualizarDocumento = ({ open, setOpen, nombreDocumento }) => {
  const [documentoAux, setDocumentoAux] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const respuestaDocumento = await consultarPDFEstado(nombreDocumento);

        if (respuestaDocumento.state) {
          if (nombreDocumento.split(".")[1] === "pdf") {
            const stringPDF = `data:application/pdf;base64,${respuestaDocumento.data}`;

            setDocumentoAux(stringPDF);
          } else {
            const stringPNG = `data:image/png;base64,${respuestaDocumento.data}`;

            setDocumentoAux(stringPNG);
          }
          /* setDocumentoAux(respuestaDocumento.data); */
          setIsLoading(false);
        } else {
          setIsLoading(false);
          alert("Documento No Encontrado");
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    if (open) {
      getData();
    }

    return () => {
      setIsLoading(true);
    };
  }, [nombreDocumento]);

  if (isLoading && open) {
    return <SpinnerLoadSrx />;
  }

  return (
    <div>
      <BootstrapDialog
        onClose={() => setOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <iframe
          id="frame_docs"
          /* src={estaoPdf} */
          src={documentoAux}
          title="resumeClient"
          width="100"
          height="1100"
          frameBorder="0"
          allowfullscreen
          //style="position: absolute; top: 50px; left: 50px;"
          style={{
            transform: `scale(${1.2})`,
            width: " 64vw",
            height: "102vh",
            border: "  0px",
            zoom: " 0.9,",
          }}
        />
      </BootstrapDialog>
    </div>
  );
};

export default VisualizarDocumento;
