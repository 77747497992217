import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFormikContext, Formik, Form, Field, useField } from "formik";

import {
  Alert,
  Button,
  Grid,
  Stack,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import InputLabel from "@mui/material/InputLabel";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Select from "@mui/material/Select";

import "../styles/main.css";

import {
  cambiarEstadoSolicitud,
  grabarSolicitud,
  listarSolicitudesNegadas,
  obtenerDocumento,
  obtenerDocumentosVisados,
  visarDocumento,
} from "../services/creditoServices";

import handleDownlPdf from "app/utils/document/documentosService";
import { SCSLoadingButton } from "app/modules/common/components/Buttons/SCSButtons";
import SCSAccordion from "app/modules/common/components/Accordions/SCSAccordion";

const FormVisado = ({ idSolic, idCliente, identificacion, solic }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  const [idClienteForm, setIdClienteForm] = useState(null);
  const [clienteInfo, setClienteInfo] = useState(null);
  const [solicitudesNeg, setSolicitudesNeg] = useState([]);
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);
  const [archivoDescargado, setArchivoDescargado] = useState(false);
  const [mensajeError, setMensajeError] = useState("");
  const [mensajeErrorCambioSolicitud, setMensajeErrorCambioSolicitud] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    cedula: false,
    solicitud: false,
    seguro: false,
  });

  useEffect(() => {
    console.log(idSolic, solic);
    setSeleccionadoGravamen(solic.segDesgravamen);
    getDocumentosVisados(idSolic);
    getSolicitudesNeg(idCliente);
  }, []);

  const getSolicitudesNeg = (idCli) => {
    listarSolicitudesNegadas(idCli)
      .then((response) => {
        if (response.state)
          setSolicitudesNeg(response.data); //   setea la consulta de usuarios
        else alert(response.message);
      })
      .catch((_errors) => {
        console.log(_errors);
      });
  };

  const getDocumentosVisados = (idSolicitud) => {
    obtenerDocumentosVisados(idSolicitud)
      .then((response) => {
        if (response.state) {
          verificarDocumentos(response.data);
        }
      })
      .catch((_errors) => {
        console.log(_errors);
      });
  };

  const verificarDocumentos = (documentos) => {
    const array = [];
    Object.keys(documentos).forEach((e) => {
      array.push(documentos[e].documento);
    });
    const ced = array.some((e) => e === "DOC01");
    const sol = array.some((e) => e === "DOC02");
    const seg = array.some((e) => e === "DOC03");
    setState({ cedula: ced, solicitud: sol, seguro: seg });
  };

  const obtenerDoc = async () => {
    console.log("Intentando descargar", identificacion);
    await obtenerDocumento(idSolic)
      .then((response) => {
        if (response.state) {
          handleDownlPdf(response.data, `Solicitud_Credito_${identificacion}`);
          setArchivoDescargado(true);
        } else {
          alert("No existe documentacion subida");
        }
        setTimeout(() => {
          setArchivoDescargado(false);
        }, 3000);
      })
      .catch((error) => {
        alert("No existe Documentacion subida");
      });
  };

  const handleCheck = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };
  const { cedula, solicitud, seguro } = state;

  const configuracionDatosEnvio = async (values) => {
    try {
      console.log("Envio de solicitud al POST");

      solic.fechaCorte = values.fechaCorte;
      solic.segDesgravamen = seleccionadoGravamen;
      console.log(solic);

      const respGrabarSolicitud = grabarSolicitud(solic).then((response) => {
        console.log(response);
      });
      setDatosEnviadosCorrectamente(true);

      setTimeout(() => {
        setDatosEnviadosCorrectamente(false);
      }, 5000);
    } catch (error) {
      console.log(error);
    }

    try {
      var resp = await cambiarEstadoSolicitud(
        idSolic,
        3,
        authUser.key,
        authUser.estacion
      );
      if (resp.state) {
        if (cedula) {
          try {
            console.log("Visado de Cedula al POST");

            const respuestavisado = visarDocumento(
              idSolic,
              "DOC01",
              authUser.key,
              authUser.estacion
            );

            console.log(respuestavisado.data);
            setDatosEnviadosCorrectamente(true);

            setTimeout(() => {
              setDatosEnviadosCorrectamente(false);
            }, 5000);
          } catch (error) {
            console.log(error);
          }
        }
        if (solicitud) {
          try {
            console.log("Visado de solicitud al POST");

            const respuestaSolicitud = visarDocumento(
              idSolic,
              "DOC02",
              authUser.key,
              authUser.estacion
            );

            console.log(respuestaSolicitud.data);
            setDatosEnviadosCorrectamente(true);

            setTimeout(() => {
              setDatosEnviadosCorrectamente(false);
            }, 5000);
          } catch (error) {
            console.log(error);
          }
        }
        if (seguro) {
          try {
            console.log("Visado de Seguro al POST");

            const respuestaSeguro = visarDocumento(
              idSolic,
              "DOC03",
              authUser.key,
              authUser.estacion
            );

            console.log(respuestaSeguro.data);
            setDatosEnviadosCorrectamente(true);

            setTimeout(() => {
              setDatosEnviadosCorrectamente(false);
            }, 5000);
          } catch (error) {
            console.log(error);
          }
        }
      } else {
        setMensajeErrorCambioSolicitud(true);

        setTimeout(() => {
          setMensajeError(resp.message);
          setMensajeErrorCambioSolicitud(false);
        }, 5000);
      }
    } catch (e) {
      console.log(e);
      setMensajeErrorCambioSolicitud(true);

      setTimeout(() => {
        setMensajeError(e.message);
        setMensajeErrorCambioSolicitud(false);
      }, 5000);
    }
  };



  const rows = solicitudesNeg;

  const [seleccionadoGravamen, setSeleccionadoGravamen] = useState(true);
  const cambioSelectGrav = (event) => {
    setSeleccionadoGravamen(event.target.checked);
    console.log(seleccionadoGravamen);
  };

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Stack direction="row" alignItems="center" spacing={2}>
        <Select
          className="select-solicitud-local"
          labelId={name}
          name={name}
          value={value}
          label={name}
          size="small"
          onChange={(e) => {
            setFieldValue(name, e.target.value);
          }}
        >
          {children}
        </Select>
      </Stack>
    );
  };

  return (
    <>
      <SCSAccordion tituloCabecera="Visado de Documentos Habilitantes">
        <Formik
          initialValues={{
            fechaCorte: solic.fechaCorte,
          }}
          onSubmit={(values, { setSubmitting }) => {
            //console.log(values);
            configuracionDatosEnvio(values);
          }}
        >
          {({ values, handleChange }) => (
            <Form>
              <div className="accordion-container">
                <Grid
                  container
                  justifyContent="center"
                  spacing={3}
                  rowSpacing={4}
                >
                  <Grid item xl={12}>
                    <Grid
                      container
                      spacing={15}
                      rowSpacing={4}
                      alignItems="flex-end"
                      justifyContent="center"
                    >
                      <Grid item xl={6}>
                        <FormControl fullWidth>
                          <InputLabel id="fechaCorte">
                            Fecha de Corte
                          </InputLabel>
                          <Field
                            name="fechaCorte"
                            label="Fecha de Corte"
                            component={selectPersonalizarPropiedades}
                          >
                            <MenuItem value={1}>
                              Corte 15 - Fecha de pago 16 al 30
                            </MenuItem>
                            <MenuItem value={2}>
                              Corte 30 - Fecha de pago 1 al 15
                            </MenuItem>
                          </Field>
                        </FormControl>
                      </Grid>
                      <Grid item xl={6}>
                        <FormGroup required>
                          <FormControlLabel
                            name="desgravamen"
                            control={<Checkbox />}
                            label="Seguro de desgravamen"
                            labelPlacement="bottom"
                            checked={seleccionadoGravamen}
                            onChange={cambioSelectGrav}
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xl={6}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={cedula}
                                onChange={handleCheck}
                                name="cedula"
                              />
                            }
                            label="Cedula de identidad"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={solicitud}
                                onChange={handleCheck}
                                name="solicitud"
                              />
                            }
                            label="Solicitud de Credito y Contrato"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={seguro}
                                onChange={handleCheck}
                                name="seguro"
                              />
                            }
                            label="Seguro de vida y desgravamen"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xl={6}>
                        {archivoDescargado && (
                          <Alert severity="success" variant="filled">
                            Se ha Descargado el Archivo.
                          </Alert>
                        )}
                        <Button
                          className="buscador-botones-preev"
                          variant="contained"
                          onClick={obtenerDoc}
                        >
                          Visualizar documentacion
                        </Button>
                      </Grid>
                      <Grid item xl={12}>
                        <div className="buscador-botones-preev">
                          <SCSLoadingButton
                            variant="contained"
                            titulo={"Grabar"}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {datosEnviadosCorrectamente && (
                  <Alert severity="success" variant="filled">
                    Los datos han sido guardados correctamente.
                  </Alert>
                )}
                {mensajeErrorCambioSolicitud && (
                  <Alert severity="error" variant="filled">
                    {mensajeError}
                  </Alert>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </SCSAccordion>
      <h3>Solicitudes negadas y devueltas del cliente</h3>
      <Box sx={{ height: 200, width: "100%" }}>
        <Table size="small" aria-label="simple table">
          <TableHead sx={{ bgcolor: "text.disabled" }}>
            <TableRow>
              <TableCell align="center">Num. Solicitud</TableCell>
              <TableCell align="center">Estado</TableCell>
              <TableCell align="center">Monto Solicitado</TableCell>
              <TableCell align="center"> Fecha</TableCell>
              <TableCell align="center"> Motivo</TableCell>
              <TableCell align="center"> Submotivo</TableCell>
              <TableCell align="center"> Observacion</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow>
                <TableCell align="center">{row.numeroSolicitud}</TableCell>
                <TableCell align="center">{row.estado}</TableCell>
                <TableCell align="center">${row.montoSolicitado}</TableCell>
                <TableCell align="center">{row.fechaSolicitud}</TableCell>
                <TableCell align="center">{row.motivo}</TableCell>
                <TableCell align="center">{row.submotivo}</TableCell>
                <TableCell align="center">{row.observacion}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <></>
    </>
  );
};

export default FormVisado;
