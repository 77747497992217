import { enviarNotificacionEmail } from "../services/sheredServices";

class EmailEstructura {
  /**
   * Crea una instancia de Notificacion.
   * @param {number} grupoId
   * @param {string} codigoComunicacion
   * @param {string} espacioComunicacion
   * @param {string} tipoComunicacion
   * @param {string} variables
   * @param {string} destino
   * @param {number} idTransaccion
   */

  constructor(
    grupoId,
    codigoComunicacion,
    espacioComunicacion,
    tipoComunicacion,
    variables,
    destino,
    idTransaccion
  ) {
    this.IdGrupo = grupoId;
    this.CodigoComunicacion = codigoComunicacion;
    this.EspacioComunicacion = espacioComunicacion;
    this.TipoComunicacion = tipoComunicacion;
    this.Variables = variables;
    this.Destino = destino;
    this.IdTransaccion = idTransaccion;
    this.AdjuntoMensaje = null;
  }

  setVariables(variables) {
    this.Variables = variables;
  }

  addArchivoAdjunto(nombre, data) {
    this.AdjuntoMensaje = [];

    this.AdjuntoMensaje.push({
      NombreAdjunto: nombre,
      Adjunto: data,
    });
  }

  /**
   * Envía un correo electrónico con la notificación.
   * @returns {Promise<Object>}
   */

  async sendEmail() {
    const estructuraNotificacion = this.getNotificacion();

    try {
      const respuestaEmailNotificacion = await enviarNotificacionEmail(
        estructuraNotificacion
      );

      return respuestaEmailNotificacion;
    } catch (error) {
      console.log(error);
    }
  }

  getNotificacion() {
    return {
      IdGrupo: this.IdGrupo,
      CodigoComunicacion: this.CodigoComunicacion,
      EspacioComunicacion: this.EspacioComunicacion,
      TipoComunicacion: this.TipoComunicacion,
      Variables: this.Variables,
      Destino: this.Destino,
      IdTransaccion: this.IdTransaccion,
      AdjuntoMensaje: this.AdjuntoMensaje,
    };
  }
}

export default EmailEstructura;
