import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import {
  Alert,
  FormControl,
  FormHelperText,
  InputLabel,
  Grid,
  MenuItem,
  TextField,
  Select,
} from "@mui/material";

import {
  consultaClienteXidCliente,
  consultarInformacionDemograficaIdentificacion,
  grabarClienteOrquestacion,
  obtenerPersonaCorporativa,
} from "../services/clienteService";

import { consultaCatalogosSinPadre } from "../services/Catalogos/catalogosService";
import { formatoFecha } from "../../shared/validations/validaciones";

import { SCSLoadingButton } from "app/modules/common/components/Buttons/SCSButtons";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";
import SCSAccordion from "app/modules/common/components/Accordions/SCSAccordion";
import { StyleInputs } from "../styles/themes";
import SkeletonForm from "app/modules/common/components/Skeleton/SkeletonForm";

const FormInformacionCliente = ({
  idCliente,
  identificacion,
  setIdClienteInformacion,
  setValidarConyugue,
  setInfoClienteGrabada,
  esSiniestroGO = false,
}) => {
  const { authUser } = useSelector(({ auth }) => auth);

  const [clienteInfo, setClienteInfo] = useState({});
  const [idClienteAux, setIdClienteAux] = useState(idCliente);

  const [controlExpand, setControlExpand] = useState({
    controlExpanded: true,
    controlSave: false,
  });

  //Estado que controla si los campos son para RUC || CEDULA (Nombre - Razon Social)
  const [tipoIdentificacionValidaciones, setTipoIdentificacionValidaciones] =
    useState(false);

  const [datosValidacionIndentificacion, setDatosValidacionIndentificacion] =
    useState({
      codigoDactilar: "",
      fechaExpedicion: "",
    });

  const [informacionIncompleta, setInformacionIncompleta] = useState(false);

  const [
    idInformacionDemograficavalidacion,
    setIdInformacionDemograficavalidacion,
  ] = useState(-1);

  //Estados para cargar data y guardar informacion
  const [loading, setLoading] = useState(false);
  const [loadingComponent, setLoadingComponent] = useState(true);
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);

  const [existeCliente, setExisteCliente] = useState(false);

  useEffect(() => {
    if (idClienteAux > 0) {
      setIdClienteAux(idClienteAux);
    }

    const getData = async () => {
      const idGrupo = authUser.usuarioFlujos[0]
        ? authUser.usuarioFlujos[0].idGrupo
        : 0;
      //cuando idCliente < 0 --> cliente NO esxiste
      if (idClienteAux <= 0) {
        setExisteCliente(false);

        let informacionDemograficaEvaluacion;
        localStorage.setItem("contacto", JSON.stringify(null));

        // try {
        //   const informacionUbicabilidad = await obtenerPersonaCorporativa(
        //     identificacion,
        //     authUser.usuarioFlujos[0].idGrupo
        //   );
        //   let contacto;
        //   contacto =
        //     informacionUbicabilidad.data.validacion === "1"
        //       ? null
        //       : informacionUbicabilidad.data.contacto;
        //   localStorage.setItem("contacto", JSON.stringify(contacto));
        // } catch (error) {
        //   console.log("Error al obtener Contacto CORP");
        // }

        try {
          const informacionDemografica =
            await consultarInformacionDemograficaIdentificacion(
              identificacion,
              idGrupo
            );

          //AQUI ESTA LA INFORMACION DEL COD. DACTILAR
          console.log({ informacionDemografica });

          if (informacionDemografica.state) {
            setDatosValidacionIndentificacion((state) => ({
              ...state,
              codigoDactilar: informacionDemografica.data.codigoDactilar,
              fechaExpedicion: informacionDemografica.data.fechaIdentificacion,
            }));

            setIdInformacionDemograficavalidacion(
              informacionDemografica.data.idInformacionDemografica
            );

            if (esSiniestroGO) {
              console.log("entra en siniestro ");
              setInformacionIncompleta(true);
              informacionDemograficaEvaluacion = {
                ...informacionDemografica.data,
                identificacion: identificacion,
                fechaIdentificacion: "",
              };
            } else {
              setInformacionIncompleta(true);
              informacionDemograficaEvaluacion = {
                ...informacionDemografica.data,
                identificacion: identificacion,
                codigoDactilar: "",
                fechaIdentificacion: "",
              };
            }
          } else {
            informacionDemograficaEvaluacion = {
              identificacion: identificacion,
              idCliente: -1,
              idInformacionDemografica: -1,
            };
            setInformacionIncompleta(true);
          }
        } catch (error) {
          console.log(error);
        }

        const repuestaInformacionCliente = {
          ...informacionDemograficaEvaluacion,
        };
        console.log({ repuestaInformacionCliente });

        setClienteInfo(repuestaInformacionCliente);
      } else {
        //cuando idCliente > 0 --> cliente si esxiste
        setExisteCliente(true);
        let infoCliente;
        let infoDemografica;

        try {
          const { state, data: informacionCliente } =
            await consultaClienteXidCliente(idClienteAux);

          infoCliente = informacionCliente;
          console.log(informacionCliente);
        } catch (error) {
          console.log(error);
        }

        localStorage.setItem("contacto", JSON.stringify(null));
        // try {
        //   const informacionUbicabilidad = await obtenerPersonaCorporativa(
        //     identificacion,
        //     authUser.usuarioFlujos[0].idGrupo
        //   );
        //   let contacto;
        //   contacto =
        //     informacionUbicabilidad.data.validacion === "1"
        //       ? null
        //       : informacionUbicabilidad.data.contacto;
        //   localStorage.setItem("contacto", JSON.stringify(contacto));
        // } catch (error) {
        //   console.log("Error al obtener Contacto CORP");
        // }

        try {
          const informacionDemografica =
            await consultarInformacionDemograficaIdentificacion(
              infoCliente.identificacion,
              idGrupo
            );

          console.log(informacionDemografica);

          if (informacionDemografica.state) {
            setIdInformacionDemograficavalidacion(
              informacionDemografica.data.idInformacionDemografica
            );

            if (informacionDemografica.data.idInformacionDemografica <= 0) {
              setDatosValidacionIndentificacion((state) => ({
                ...state,
                codigoDactilar: informacionDemografica.data.codigoDactilar,
                fechaExpedicion:
                  informacionDemografica.data.fechaIdentificacion,
              }));

              infoDemografica = {
                ...informacionDemografica.data,
                codigoDactilar: "",
                fechaIdentificacion: "",
              };

              console.log({ infoDemografica });
            } else {
              infoDemografica = informacionDemografica.data;
            }
          } else {
            infoDemografica = {
              idInformacionDemografica: -1,
            };
          }
        } catch (error) {
          console.log(error);
        }

        const res = {
          ...infoCliente,
          ...infoDemografica,
        };

        console.log({ res });
        setClienteInfo(res);
      }
      setLoadingComponent(false);
    };

    getData();
  }, [idClienteAux]);

  if (loadingComponent) {
    return <SkeletonForm />;
  }

  const SelectItemsCatalogoSinPadre = ({
    pais,
    especial,
    catalogos,
    grupo,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultaCatalogosSinPadre(
            especial,
            catalogos,
            grupo
          );

          if (state) {
            setProvincias(data);
          } else {
            setProvincias([]);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getData();
    }, []);

    return (
      <FormControl
        //sx={{ width: `${componentSize}` }}
        sx={StyleInputs(componentSize.pcWidth, componentSize.phoneWidth)}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {provincias.map((provincia, index) => (
            <MenuItem key={index} value={provincia.codigo}>
              {provincia.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const validarEstadoCivil = (estadoCivil) => {
    if (setValidarConyugue) {
      if (estadoCivil === "CAS" || estadoCivil === "UDH") {
        setValidarConyugue(true);
      } else {
        setValidarConyugue(false);
      }
    }
  };

  //Funcion que controla inputs para RUC || CEDULA && validaciones
  const camposPorTipoIdentificacion = (tipoIdentificacion) => {
    if (tipoIdentificacion === "RUC") {
      setTipoIdentificacionValidaciones(true);

      return false;
    }
    setTipoIdentificacionValidaciones(false);
    return true;
  };

  const validarCodigoIdentificacion = (codigo) => {
    if (esSiniestroGO) {
      return false;
    }

    if (idInformacionDemograficavalidacion >= 0 || codigo === "") {
      return false;
    }

    return (
      datosValidacionIndentificacion.codigoDactilar.toUpperCase() !==
      codigo.toUpperCase()
    );
  };

  /*   const validarFechaExpedicionValidacion = (fechaExpedicion) => {
    if (idInformacionDemograficavalidacion < 0) {

      if (!fechaExpedicion) {
        return false;
      }

      if (
        datosValidacionIndentificacion.fechaExpedicion.split("T")[0] ===
        fechaExpedicion
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }; */

  const validarFechaExpedicionValidacion = (fechaExpedicion) => {
    if (idInformacionDemograficavalidacion < 0) {
      if (!fechaExpedicion) {
        return true;
      }
      if (
        datosValidacionIndentificacion.fechaExpedicion.split("T")[0] ===
        fechaExpedicion
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const configuracionDatosEnvio = async (valores) => {
    setLoading(true);

    const informacionCliente = {
      idCliente: Number(
        `${clienteInfo.idCliente <= 0 ? 0 : clienteInfo.idCliente}`
      ),
      idGrupo: 1,
      identificacion: valores.identificacion,
      tipoIdentificacion: valores.tipoIdentificacion,
      naturaleza: clienteInfo.naturaleza ?? "PERS01",

      perfilRiesgo: clienteInfo.perfilRiesgo ?? "",
      tipoCliente: clienteInfo.tipoCliente ?? "",
      score: clienteInfo.score ?? 0,
      origen: clienteInfo.origen ?? "",
      actualizado: clienteInfo.actualizado ?? false,
      esActivo: true,
    };

    /*  console.log("Informacion Cliente");
    console.log({ informacionCliente }); */

    const informacionDemografica = {
      primerNombre: valores.primerNombre.toUpperCase().trim(),
      primerApellido: valores.primerApellido.toUpperCase().trim(),
      segundoNombre: valores.segundoNombre.toUpperCase().trim(),
      segundoApellido: valores.segundoApellido.toUpperCase().trim(),
      estadoCivil: valores.estadoCivil,
      fechaNacimiento: valores.fechaNacimiento,
      genero: valores.genero,
      nacionalidad: valores.nacionalidad,
      nivelInstruccion: valores.nivelInstruccion,
      razonSocial: valores.razonSocial.toUpperCase(),
      nombreComercial: valores.nombreComercial.toUpperCase().trim(),
      codigoDactilar: valores.codigoDactilar.toUpperCase().trim(),
      fechaIdentificacion: valores.fechaIdentificacion,
      esFallecido: clienteInfo.esFallecido ?? false,
      esActivo: true,
    };

    /* console.log("Informacion Demografica");
    console.log({ informacionDemografica }); */

    const informacionOrquestacion = {
      cliente: { ...informacionCliente },
      demografica: { ...informacionDemografica },
    };

    /*  console.log(informacionOrquestacion); */

    try {
      const respuestaClienteOrquestacion = await grabarClienteOrquestacion(
        informacionOrquestacion
      );

      console.log({ respuestaClienteOrquestacion });

      if (respuestaClienteOrquestacion.state) {
        setInformacionIncompleta(false);
        setDatosEnviadosCorrectamente(true);
        setIdClienteAux(respuestaClienteOrquestacion.data);
        setIdClienteInformacion(respuestaClienteOrquestacion.data);
        if (setInfoClienteGrabada) {
          setInfoClienteGrabada((args) => ({
            ...args,
            infoCliente: true,
          }));
        }

        setTimeout(() => {
          setLoading(false);
          setDatosEnviadosCorrectamente(false);
          handleExpand();
          setControlExpand((args) => ({
            ...args,
            controlSave: true,
          }));
        }, 1000);
      } else {
        setIdClienteInformacion(-1);
        setLoading(false);
        alert(`${respuestaClienteOrquestacion.message}`);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert(`${error.response.data.title}`);
    }
  };

  const handleExpand = () => {
    setControlExpand((args) => ({
      ...args,
      controlExpanded: !args.controlExpanded,
    }));
  };

  const validacionTipoIdentificacion = () => {
    if (esSiniestroGO) {
      if (existeCliente) {
        if (!tipoIdentificacionValidaciones) {
          return {
            tipoIdentificacion: Yup.string().required(
              "Tipo identificación requerido"
            ),
            genero: Yup.string().required("Genero requerido"),
            nacionalidad: Yup.string().required("Nacionalidad requerida"),
            estadoCivil: Yup.string().required("Estado Civil requerido"),
            identificacion: Yup.string()
              .max(13, "Debería tener máximo 13 caracteres")
              .min(10, "Debería tener al menos 10 caracteres")
              .required("Identificación requerida"),
            primerNombre: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres")
              .required("Primer Nombre requerida"),
            segundoNombre: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres"),
            primerApellido: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres")
              .required("Primer Apellido requerida"),
            segundoApellido: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres"),
            fechaNacimiento: Yup.string().required(
              "Fecha Nacimiento requerida"
            ),
            //codigoDactilar: Yup.string().required("Código Dactilar requerido"),
          };
        } else {
          return {
            tipoIdentificacion: Yup.string().required(
              "Tipo identificación requerido"
            ),
            genero: Yup.string().required("Genero requerido"),
            nacionalidad: Yup.string().required("Nacionalidad requerida"),
            estadoCivil: Yup.string().required("Estado Civil requerido"),
            identificacion: Yup.string()
              .max(13, "Debería tener máximo 13 caracteres")
              .min(10, "Debería tener al menos 10 caracteres")
              .required("Identificación requerida"),
            razonSocial: Yup.string()
              .max(40, "Debería tener máximo 40 caracteres")
              .min(3, "Debería tener al menos 3 caracteres")
              .required("Razón Social requerida"),
            nombreComercial: Yup.string()
              .max(40, "Debería tener máximo 40 caracteres")
              .min(3, "Debería tener al menos 3 caracteres")
              .required("Nombre Comercial requerida"),
            primerNombre: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres"),
            segundoNombre: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres"),
            primerApellido: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres"),
            segundoApellido: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres"),
            fechaNacimiento: Yup.string().required(
              "Fecha Nacimiento requerida"
            ),
            //codigoDactilar: Yup.string().required("Código Dactilar requerido"),
          };
        }
      } else {
        if (!tipoIdentificacionValidaciones) {
          return {
            tipoIdentificacion: Yup.string().required(
              "Tipo identificación requerido"
            ),
            genero: Yup.string().required("Genero requerido"),
            nacionalidad: Yup.string().required("Nacionalidad requerida"),
            estadoCivil: Yup.string().required("Estado Civil requerido"),
            identificacion: Yup.string()
              .max(13, "Debería tener máximo 13 caracteres")
              .min(10, "Debería tener al menos 10 caracteres")
              .required("Identificación requerida"),
            primerNombre: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres")
              .required("Primer Nombre requerida"),
            segundoNombre: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres"),
            primerApellido: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres")
              .required("Primer Apellido requerida"),
            segundoApellido: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres"),
            fechaNacimiento: Yup.string().required(
              "Fecha Nacimiento requerida"
            ),
            //codigoDactilar: Yup.string().required("Código Dactilar requerido"),
          };
        } else {
          return {
            tipoIdentificacion: Yup.string().required(
              "Tipo identificación requerido"
            ),
            genero: Yup.string().required("Genero requerido"),
            nacionalidad: Yup.string().required("Nacionalidad requerida"),
            estadoCivil: Yup.string().required("Estado Civil requerido"),
            identificacion: Yup.string()
              .max(13, "Debería tener máximo 13 caracteres")
              .min(10, "Debería tener al menos 10 caracteres")
              .required("Identificación requerida"),
            razonSocial: Yup.string()
              .max(40, "Debería tener máximo 40 caracteres")
              .min(3, "Debería tener al menos 3 caracteres")
              .required("Razón Social requerida"),
            nombreComercial: Yup.string()
              .max(40, "Debería tener máximo 40 caracteres")
              .min(3, "Debería tener al menos 3 caracteres")
              .required("Nombre Comercial requerida"),
            primerNombre: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres"),
            segundoNombre: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres"),
            primerApellido: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres"),
            segundoApellido: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres"),
            fechaNacimiento: Yup.string().required(
              "Fecha Nacimiento requerida"
            ),
            //codigoDactilar: Yup.string().required("Código Dactilar requerido"),
          };
        }
      }
    } else {
      if (existeCliente) {
        if (!tipoIdentificacionValidaciones) {
          return {
            tipoIdentificacion: Yup.string().required(
              "Tipo identificación requerido"
            ),
            genero: Yup.string().required("Genero requerido"),
            nacionalidad: Yup.string().required("Nacionalidad requerida"),
            estadoCivil: Yup.string().required("Estado Civil requerido"),
            identificacion: Yup.string()
              .max(13, "Debería tener máximo 13 caracteres")
              .min(10, "Debería tener al menos 10 caracteres")
              .required("Identificación requerida"),
            primerNombre: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres")
              .required("Primer Nombre requerida"),
            segundoNombre: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres"),
            primerApellido: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres")
              .required("Primer Apellido requerida"),
            segundoApellido: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres"),
            fechaNacimiento: Yup.string().required(
              "Fecha Nacimiento requerida"
            ),
            //codigoDactilar: Yup.string().required("Código Dactilar requerido"),
          };
        } else {
          return {
            tipoIdentificacion: Yup.string().required(
              "Tipo identificación requerido"
            ),
            genero: Yup.string().required("Genero requerido"),
            nacionalidad: Yup.string().required("Nacionalidad requerida"),
            estadoCivil: Yup.string().required("Estado Civil requerido"),
            identificacion: Yup.string()
              .max(13, "Debería tener máximo 13 caracteres")
              .min(10, "Debería tener al menos 10 caracteres")
              .required("Identificación requerida"),
            razonSocial: Yup.string()
              .max(40, "Debería tener máximo 40 caracteres")
              .min(3, "Debería tener al menos 3 caracteres")
              .required("Razón Social requerida"),
            nombreComercial: Yup.string()
              .max(40, "Debería tener máximo 40 caracteres")
              .min(3, "Debería tener al menos 3 caracteres")
              .required("Nombre Comercial requerida"),
            primerNombre: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres"),
            segundoNombre: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres"),
            primerApellido: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres"),
            segundoApellido: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres"),
            fechaNacimiento: Yup.string().required(
              "Fecha Nacimiento requerida"
            ),
            codigoDactilar: Yup.string().required("Código Dactilar requerido"),
          };
        }
      } else {
        if (!tipoIdentificacionValidaciones) {
          return {
            tipoIdentificacion: Yup.string().required(
              "Tipo identificación requerido"
            ),
            genero: Yup.string().required("Genero requerido"),
            nacionalidad: Yup.string().required("Nacionalidad requerida"),
            estadoCivil: Yup.string().required("Estado Civil requerido"),
            identificacion: Yup.string()
              .max(13, "Debería tener máximo 13 caracteres")
              .min(10, "Debería tener al menos 10 caracteres")
              .required("Identificación requerida"),
            primerNombre: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres")
              .required("Primer Nombre requerida"),
            segundoNombre: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres"),
            primerApellido: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres")
              .required("Primer Apellido requerida"),
            segundoApellido: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres"),
            fechaNacimiento: Yup.string().required(
              "Fecha Nacimiento requerida"
            ),
            codigoDactilar: Yup.string().required("Código Dactilar requerido"),
          };
        } else {
          return {
            tipoIdentificacion: Yup.string().required(
              "Tipo identificación requerido"
            ),
            genero: Yup.string().required("Genero requerido"),
            nacionalidad: Yup.string().required("Nacionalidad requerida"),
            estadoCivil: Yup.string().required("Estado Civil requerido"),
            identificacion: Yup.string()
              .max(13, "Debería tener máximo 13 caracteres")
              .min(10, "Debería tener al menos 10 caracteres")
              .required("Identificación requerida"),
            razonSocial: Yup.string()
              .max(40, "Debería tener máximo 40 caracteres")
              .min(3, "Debería tener al menos 3 caracteres")
              .required("Razón Social requerida"),
            nombreComercial: Yup.string()
              .max(40, "Debería tener máximo 40 caracteres")
              .min(3, "Debería tener al menos 3 caracteres")
              .required("Nombre Comercial requerida"),
            primerNombre: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres"),
            segundoNombre: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres"),
            primerApellido: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres"),
            segundoApellido: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres"),
            fechaNacimiento: Yup.string().required(
              "Fecha Nacimiento requerida"
            ),
            codigoDactilar: Yup.string().required("Código Dactilar requerido"),
          };
        }
      }
    }
  };

  return (
    <>
      {informacionIncompleta && (
        <Alert
          color="warning"
          severity="warning"
          sx={{ padding: 1, margin: 1 }}
        >
          Cliente NO existe.!! Complete y guarde la información.
        </Alert>
      )}

      <Formik
        initialValues={{
          tipoIdentificacion: clienteInfo.tipoIdentificacion ?? "CI",
          identificacion: clienteInfo.identificacion ?? "",
          estadoCivil: clienteInfo.estadoCivil ?? "",
          nacionalidad: clienteInfo.nacionalidad ?? "",
          primerNombre: clienteInfo.primerNombre ?? "",
          segundoNombre: clienteInfo.segundoNombre ?? "",
          primerApellido: clienteInfo.primerApellido ?? "",
          segundoApellido: clienteInfo.segundoApellido ?? "",
          fechaNacimiento: clienteInfo.fechaNacimiento ?? "",
          nivelInstruccion: clienteInfo.nivelInstruccion ?? "",

          tipoCliente: clienteInfo.tipoCliente ?? "",
          genero: clienteInfo.genero ?? "",

          razonSocial: clienteInfo.razonSocial ?? "",
          nombreComercial: clienteInfo.nombreComercial ?? "",

          codigoDactilar: clienteInfo.codigoDactilar ?? "",
          fechaIdentificacion: clienteInfo.fechaIdentificacion ?? "",
        }}
        enableReinitialize={true}
        validate={(values) => {
          const errors = {};

          if (values.tipoIdentificacion === "RUC") {
            if (!/^[0-9]{0,15}$/.test(values.identificacion)) {
              errors.identificacion = "Solo se aceptan números";
            }
          }
          if (values.tipoIdentificacion === "CI") {
            if (!/^[0-9]{0,15}$/.test(values.identificacion)) {
              errors.identificacion = "Solo se aceptan números";
            }
          }

          if (!/^[a-zA-ZÑñ" "]{0,16}$/.test(values.primerNombre)) {
            errors.primerNombre =
              "El nombre solo puede contener caracteres sin espacios";
          }
          if (!/^[a-zA-ZÑñ" "]{0,16}$/.test(values.segundoNombre)) {
            errors.segundoNombre =
              "El nombre solo puede contener caracteres sin espacios";
          }
          if (!/^[a-zA-ZÑñ" "]{0,16}$/.test(values.primerApellido)) {
            errors.primerApellido =
              "El apellido solo puede contener caracteres sin espacios";
          }
          if (!/^[a-zA-ZÑñ" "]{0,16}$/.test(values.segundoApellido)) {
            errors.segundoApellido =
              "El apellido solo puede contener caracteres sin espacios";
          }

          if (validarCodigoIdentificacion(values.codigoDactilar)) {
            errors.codigoDactilar = "Datos Inconsistentes";
          }

          return errors;
        }}
        validationSchema={Yup.object(validacionTipoIdentificacion())}
        onSubmit={(values, { setSubmitting }) => {
          configuracionDatosEnvio(values);
        }}
      >
        {({ values, handleChange, touched, errors, handleBlur }) => (
          <Form>
            <SCSAccordion
              tituloCabecera="Información del Cliente"
              controlExpand={controlExpand}
              handleExpand={handleExpand}
            >
              <div className="cliente-informacion-seccion">
                <SelectItemsCatalogoSinPadre
                  pais="ECU"
                  especial="false"
                  catalogos="TIPOIDEN"
                  grupo={1}
                  name="tipoIdentificacion"
                  labelName="Tipo ID"
                  componentSize={{ pcWidth: 16, phoneWidth: 45 }}
                  handleBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                />

                <TextField
                  id="identificacion"
                  name="identificacion"
                  type="text"
                  label="Identificación"
                  sx={StyleInputs(14, 45)}
                  value={values.identificacion}
                  onChange={handleChange}
                  size="small"
                  error={
                    touched.identificacion && Boolean(errors.identificacion)
                  }
                  helperText={touched.identificacion && errors.identificacion}
                  onBlur={handleBlur}
                />

                {camposPorTipoIdentificacion(values.tipoIdentificacion) ? (
                  <>
                    <TextField
                      id="primerApellido"
                      name="primerApellido"
                      type="text"
                      label="Primer Apellido"
                      sx={StyleInputs(14, 45)}
                      value={values.primerApellido}
                      onChange={handleChange}
                      size="small"
                      inputProps={{
                        style: {
                          textTransform: "uppercase",
                        },
                      }}
                      error={
                        touched.primerApellido && Boolean(errors.primerApellido)
                      }
                      helperText={
                        touched.primerApellido && errors.primerApellido
                      }
                      onBlur={handleBlur}
                    />

                    <TextField
                      id="segundoApellido"
                      name="segundoApellido"
                      type="text"
                      label="Segundo Apellido"
                      sx={StyleInputs(14, 45)}
                      value={values.segundoApellido}
                      onChange={handleChange}
                      size="small"
                      inputProps={{
                        style: {
                          textTransform: "uppercase",
                        },
                      }}
                      error={
                        touched.segundoApellido &&
                        Boolean(errors.segundoApellido)
                      }
                      helperText={
                        touched.segundoApellido && errors.segundoApellido
                      }
                      onBlur={handleBlur}
                    />

                    <TextField
                      id="primerNombre"
                      name="primerNombre"
                      type="text"
                      label="Primer Nombre"
                      sx={StyleInputs(14, 45)}
                      size="small"
                      value={values.primerNombre}
                      onChange={handleChange}
                      inputProps={{
                        style: {
                          textTransform: "uppercase",
                        },
                      }}
                      error={
                        touched.primerNombre && Boolean(errors.primerNombre)
                      }
                      helperText={touched.primerNombre && errors.primerNombre}
                      onBlur={handleBlur}
                    />

                    <TextField
                      id="segundoNombre"
                      name="segundoNombre"
                      type="text"
                      label="Segundo Nombre"
                      sx={StyleInputs(14, 45)}
                      value={values.segundoNombre}
                      onChange={handleChange}
                      size="small"
                      inputProps={{
                        style: {
                          textTransform: "uppercase",
                        },
                      }}
                      error={
                        touched.segundoNombre && Boolean(errors.segundoNombre)
                      }
                      helperText={touched.segundoNombre && errors.segundoNombre}
                      onBlur={handleBlur}
                    />
                  </>
                ) : (
                  <>
                    <TextField
                      id="razonSocial"
                      name="razonSocial"
                      type="text"
                      label="Razón Social"
                      sx={{ width: "28%" }}
                      value={values.razonSocial}
                      onChange={handleChange}
                      size="small"
                      inputProps={{
                        style: {
                          textTransform: "uppercase",
                        },
                      }}
                      error={touched.razonSocial && Boolean(errors.razonSocial)}
                      helperText={touched.razonSocial && errors.razonSocial}
                      onBlur={handleBlur}
                    />

                    <TextField
                      id="nombreComercial"
                      name="nombreComercial"
                      type="text"
                      label="Nombre Comercial"
                      sx={{ width: "28%" }}
                      value={values.nombreComercial}
                      onChange={handleChange}
                      size="small"
                      inputProps={{
                        style: {
                          textTransform: "uppercase",
                        },
                      }}
                      error={
                        touched.nombreComercial &&
                        Boolean(errors.nombreComercial)
                      }
                      helperText={
                        touched.nombreComercial && errors.nombreComercial
                      }
                      onBlur={handleBlur}
                    />
                  </>
                )}
              </div>
              <div className="cliente-informacion-seccion">
                <TextField
                  id="fechaNacimiento"
                  label="Fecha Nacimiento"
                  type="date"
                  size="small"
                  // sx={{ width: "16%" }}
                  sx={StyleInputs(16, 45)}
                  onChange={handleChange}
                  value={formatoFecha(values.fechaNacimiento)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={
                    touched.fechaNacimiento && Boolean(errors.fechaNacimiento)
                  }
                  helperText={touched.fechaNacimiento && errors.fechaNacimiento}
                  onBlur={handleBlur}
                />

                <SelectItemsCatalogoSinPadre
                  pais="ECU"
                  especial="false"
                  catalogos="GENERO"
                  grupo={1}
                  name="genero"
                  labelName="Genero"
                  //componentSize="16%"
                  componentSize={{ pcWidth: 16, phoneWidth: 45 }}
                  touched={touched}
                  errors={errors}
                />

                <SelectItemsCatalogoSinPadre
                  pais="ECU"
                  especial="false"
                  catalogos="ESTCIVIL"
                  grupo={1}
                  name="estadoCivil"
                  labelName="Estado Civil"
                  componentSize={{ pcWidth: 16, phoneWidth: 45 }}
                  handleBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                />

                {validarEstadoCivil(values.estadoCivil)}

                <SelectItemsCatalogoSinPadre
                  pais="ECU"
                  especial="false"
                  catalogos="NACION"
                  grupo={1}
                  name="nacionalidad"
                  labelName="Nacionalidad"
                  componentSize={{ pcWidth: 16, phoneWidth: 45 }}
                  handleBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                />

                <SelectItemsCatalogoSinPadre
                  pais="ECU"
                  especial="false"
                  catalogos="NIVEDUC"
                  grupo={1}
                  name="nivelInstruccion"
                  labelName="Nivel Instrucción"
                  //componentSize={{ pcWidth: 65, phoneWidth: 20 }}
                  componentSize={{ pcWidth: 20, phoneWidth: 65 }}
                  handleBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                />
              </div>
              <div className="cliente-informacion-seccion">
                <TextField
                  id="codigoDactilar"
                  name="codigoDactilar"
                  type="text"
                  label="Código Dactilar"
                  sx={StyleInputs(18, 45)}
                  value={values.codigoDactilar}
                  onChange={handleChange}
                  size="small"
                  inputProps={{
                    style: {
                      textTransform: "uppercase",
                    },
                  }}
                  error={
                    touched.codigoDactilar && Boolean(errors.codigoDactilar)
                  }
                  helperText={touched.codigoDactilar && errors.codigoDactilar}
                  onBlur={handleBlur}
                />

                <TextField
                  id="fechaIdentificacion"
                  label="Fecha Expedición"
                  type="date"
                  size="small"
                  //sx={{ width: "18%" }}
                  sx={StyleInputs(18, 45)}
                  onChange={handleChange}
                  value={formatoFecha(values.fechaIdentificacion)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={
                    touched.fechaIdentificacion &&
                    Boolean(errors.fechaIdentificacion)
                  }
                  helperText={
                    touched.fechaIdentificacion && errors.fechaIdentificacion
                  }
                  onBlur={handleBlur}
                />
              </div>

              <div className="cliente-informacion-seccion">
                {validarCodigoIdentificacion(values.codigoDactilar) && (
                  <Alert color="warning" severity="warning">
                    Porfavor Revisar Código Dactilar Ingresada.
                  </Alert>
                )}

                {validarFechaExpedicionValidacion(
                  values.fechaIdentificacion
                ) && (
                  <Alert color="warning" severity="warning">
                    Porfavor Revisar Fecha de Expedición Ingresada.
                  </Alert>
                )}
              </div>

              <div className="confirmar-estados-servicios">
                <SCSLoadingButton loading={loading} titulo="GUARDAR" />

                {datosEnviadosCorrectamente && <AcceptServiceresponse />}
              </div>
            </SCSAccordion>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormInformacionCliente;
