import React, { useEffect, useReducer, useState } from "react";

import ModalDocuments from "app/modules/common/components/Modals/ModalDocuments";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import { consultarTablaAmortizacionXAutorizacion } from "../services/transaccionalService";
import ModalHandleNotification from "app/modules/common/components/Modals/ModalHandleNotificaciones/ModalHandleNotification";
import {
  initialModalState,
  modalNotificacionReducer,
} from "app/modules/common/components/Modals/ModalHandleNotificaciones/modalNotificacionReducer";

const VisualizarTablaAmortizacion = ({ open, setOpen, autorizacion }) => {
  const [documentoAux, setDocumentoAux] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [modalState, modalDispatch] = useReducer(
    modalNotificacionReducer,
    initialModalState
  );

  useEffect(() => {
    const getTablaAmortizaciones = async () => {
      try {
        const respuestaTablaAmortizacion =
          await consultarTablaAmortizacionXAutorizacion(autorizacion);

        if (respuestaTablaAmortizacion.state) {
          const stringPDF = `data:application/pdf;base64,${respuestaTablaAmortizacion.data}`;
          setDocumentoAux(stringPDF);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setOpen(false);
          modalDispatch({
            type: "SHOW_WARNING",
            title: "AMORTIZACIÓN",
            message: "No se ha podido obtener el documento.",
          });
        }
      } catch (error) {
        setOpen(false);
        setIsLoading(false);
        modalDispatch({
          type: "SHOW_WARNING",
          title: "AMORTIZACIÓN",
          message: "No se ha podido obtener el documento.",
        });
      }
    };

    if (open) {
      getTablaAmortizaciones();
    }

    return () => {
      setIsLoading(true);
      setDocumentoAux("");
    };
  }, [autorizacion, open]);

  if (isLoading && open) {
    return <SpinnerLoadSrx />;
  }

  return (
    <>
      <ModalDocuments open={open} setOpen={setOpen}>
        <iframe
          id="frame_docs"
          src={documentoAux}
          title="resumeClient"
          width="100"
          height="1100"
          frameborder="0"
          allowfullscreen
          style={{
            transform: `scale(${1.2})`,
            width: " 64vw",
            height: "102vh",
            border: "  0px",
            zoom: " 0.9,",
          }}
        />
      </ModalDocuments>

      <ModalHandleNotification
        parameters={modalState}
        setOpen={() => modalDispatch({ type: "CLOSE_MODAL" })}
      />
    </>
  );
};

export default VisualizarTablaAmortizacion;
