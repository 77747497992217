import http from "../../../comunication/http-common";

/* eslint-disable camelcase */

//http://10.10.0.79:5000/cliente/obtenerContacto?idCliente=287056&tipo=DIRDOM
export const consultaValidacionData = async (contacto, tipoDato) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CLIENTE}cliente/obtenerValidezContacto?contacto=${contacto}&tipo=${tipoDato}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.79:5000/EnvioNotificacion/Notificar
export const enviarNotificacionEmail = async (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_NOTIFICAR}EnvioNotificacion/Notificar`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
