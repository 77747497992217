import React from "react";
import { useHistory } from "react-router";

import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
} from "@mui/material";

import { SCSGenericButton } from "../../Buttons/SCSButtons";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    minWidth: 500,
    padding: theme.spacing(2),
    borderRadius: 8,
  },
  "& .MuiDialogContent-root": {
    /* padding: theme.spacing(2), */
    textAlign: "center",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2, 2),
    justifyContent: "center",
  },
}));

const BootstrapDialogTitle = styled(DialogTitle)(({ theme }) => ({
  textAlign: "center",
  padding: theme.spacing(2),
  position: "relative",
  margin: "0",
  "& .MuiIconButton-root": {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const IconWrapper = styled("div")(({ theme, type }) => {
  let backgroundColor;
  let color;

  switch (type) {
    case "error":
      backgroundColor = theme.palette.error.main;
      color = theme.palette.error.contrastText;
      break;
    case "warning":
      backgroundColor = theme.palette.warning.main;
      color = theme.palette.warning.contrastText;
      break;
    case "info":
      backgroundColor = theme.palette.info.main;
      color = theme.palette.info.contrastText;
      break;
    case "success":
      backgroundColor = theme.palette.success.main;
      color = theme.palette.success.contrastText;
      break;
    default:
      backgroundColor = theme.palette.grey[500];
      color = theme.palette.common.white;
  }

  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor,
    color,
    width: 48,
    height: 48,
    borderRadius: "50%",
    margin: "0 auto",
    marginBottom: theme.spacing(2),
  };
});

function BootstrapDialogTitleComponent(props) {
  const { children, onClose, ...other } = props;

  return (
    <BootstrapDialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </BootstrapDialogTitle>
  );
}

BootstrapDialogTitleComponent.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ModalHandleNotification = ({
  parameters: { type, openModal, title, message, urlRoute },
  setOpen,
  children,
}) => {
  const navigate = useHistory();

  const navegarHome = () => {
    navigate.push("/initialHome");
  };

  let Icon;
  switch (type) {
    case "error":
      Icon = ErrorOutlineIcon;
      break;
    case "warning":
      Icon = WarningAmberIcon;
      break;
    case "info":
      Icon = InfoIcon;
      break;
    case "success":
      Icon = CheckCircleIcon;
      break;
    default:
      Icon = InfoIcon;
  }

  return (
    <BootstrapDialog
      onClose={() => {
        if (urlRoute) {
          setOpen();
        } else {
          navegarHome();
        }
      }}
      aria-labelledby="customized-dialog-title"
      open={openModal}
    >
      <BootstrapDialogTitleComponent
        id="customized-dialog-title"
        onClose={() => setOpen()}
      >
        <IconWrapper type={type}>
          <Icon fontSize="large" />
        </IconWrapper>
        <Typography variant="h3">{title}</Typography>
      </BootstrapDialogTitleComponent>

      <DialogContent sx={{ padding: "1.5rem 0" }} dividers>
        <Typography variant="h7">{message}</Typography>
      </DialogContent>

      {children && <DialogContent dividers>{children}</DialogContent>}

      <DialogActions>
        <SCSGenericButton
          size={55}
          type={type}
          autoFocus
          onClick={() => {
            if (urlRoute) {
              setOpen();
            } else {
              navegarHome();
            }
          }}
          titulo={"ACEPTAR"}
        />
      </DialogActions>
    </BootstrapDialog>
  );
};

ModalHandleNotification.propTypes = {
  parameters: PropTypes.shape({
    type: PropTypes.oneOf([
      "SHOW_WARNING",
      "SHOW_ERROR",
      "SHOW_INFO",
      "SHOW_SUCCESS",
      "CLOSE_MODAL",
    ]).isRequired,
    openModal: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    urlRoute: PropTypes.string,
  }).isRequired,
  setOpen: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default ModalHandleNotification;
