import React, { useState } from "react";
import { Button, Card, Grid, Stack, TextField } from "@mui/material";
import { useHistory } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { SCSGenericButton } from "app/modules/common/components/Buttons/SCSButtons";

const BuscadorPreevaluacion = ({
  traerIdentificacion,
  validarIdentificacionCF,
  validado,
  limpiar,
  loading,
  loadingPreevaluar,
}) => {
  const history = useHistory();
  const [identificacion, setidCliente] = useState();
  const [habilitado, setHabilitado] = useState(validado);

  const handleIdentificacion = (e) => {
    //console.log("cambia");
    //handleLimpiar(e.target.value);
    setidCliente(e.target.value);
  };
  const redirectHomePage = () => {
    history.push("/initialHome");
  };
  const handleLimpiar = (val) => {
    console.log(val);
    limpiar(val);
  };

  return (
    <>
      <Card className="buscador-container-preev">
        <div className="buscador-cabecera-preev">
          <h2>Ingrese el numero de cedula.</h2>
        </div>

        <div className="buscador-body-preev">
          <div className="buscador-identificacion-preev">
            <TextField
              id="buscador"
              label="Cedula"
              size="small"
              variant="outlined"
              value={identificacion}
              onChange={handleIdentificacion}
              InputProps={{
                inputProps: {
                  size: "28",
                },
              }}
            />
          </div>
          <Stack
            sx={{marginTop:3}}
            direction={{ xs : "column", sm: "column", md: "row" }}
            spacing={{ xs: 2, sm: 3, md: 3 }}
            justifyContent={"center"}
          >
            <SCSGenericButton
              loading={loading}
              //loadingPosition="end"
              variant="contained"
              onClick={validarIdentificacionCF(identificacion)}
              //disabled={displayButtonCargar}
              titulo={"Terminos y Condiciones"}
            />

            <SCSGenericButton
              //disabled={!validado}
              loading={loadingPreevaluar}
              //loadingPosition="end"
              variant="contained"
              onClick={traerIdentificacion(identificacion)}
              //disabled={displayButtonCargar}
              titulo={"Preevaluar"}
              size={20}
            />

            <SCSGenericButton
              variant="contained"
              onClick={redirectHomePage}
              titulo={"Cancelar"}
              size={20}
            />
          </Stack>
        </div>
      </Card>
    </>
  );
};

export default BuscadorPreevaluacion;
