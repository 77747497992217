import React, { useEffect, useReducer, useState } from "react";

import { Skeleton } from "@mui/material";

import {
  consultarEstadosCuenta,
  consultarPDFEstado,
  notificarEstadoCuenta,
} from "../services/transaccionalService";

import {
  SCSGenericButton,
  SCSLoadingButton,
} from "app/modules/common/components/Buttons/SCSButtons";

import {
  SCSTableData,
  SCSTableDataRow,
  SCSTableDataCell,
} from "app/modules/common/components/TableData/SCSTableData";

import VisualizarDocumento from "../components/VisualizarDocumento";
import DetalleOpcionGenerica from "./DetalleOpcionGenerica";
import ModalEstadoProceso from "app/modules/common/components/Modals/ModalEstadoProceso";
import {
  initialModalState,
  modalNotificacionReducer,
} from "app/modules/common/components/Modals/ModalHandleNotificaciones/modalNotificacionReducer";
import ModalHandleNotification from "app/modules/common/components/Modals/ModalHandleNotificaciones/ModalHandleNotification";
import EmailEstructura from "app/modules/shared/classes/EstructuraEmail";

const DetalleValoresPorPagar = ({
  cabenceraTransaccional,
  datosBusqueda,
  alturaResolucion,
}) => {
  const [modalState, modalDispatch] = useReducer(
    modalNotificacionReducer,
    initialModalState
  );

  const [isLoading, setIsLoading] = useState(true);
  const [listaAutorizaciones, setListaAutorizaciones] = useState({
    header: { identificacion: "", nombreCliente: "", numeroTarjeta: "" },
    data: [],
  });

  const [open, setOpen] = useState(false);
  const [nombreDocumento, setNombreDocumento] = useState("");

  //TODO: Funcion para ver que usuario es
  const [validarUsuario, setValidarUsuario] = useState(() => {
    const rolUsuarioString = sessionStorage.getItem("user");

    // Manejar el caso en que rolUsuarioString sea null

    if (!rolUsuarioString) {
      console.log("No se encontró ningún usuario en sessionStorage.");
      return false; // Devuelve false si no hay datos de usuario
    }

    // Intentar parsear el string a JSON
    let rolUsuario;
    try {
      rolUsuario = JSON.parse(rolUsuarioString);
    } catch (error) {
      console.log("Error al parsear el JSON:", error);
      return false; // Devuelve false si la parseo falla
    }

    // Verificar que el objeto parseado tenga la propiedad 'Rol'
    /* console.log(rolUsuario.rol); */
    if (rolUsuario && rolUsuario.rol === "GENERICOCLI") {
      return false;
    } else {
      return true;
    }
  });

  const [loadingNotificar, setLoadingNotificar] = useState(false);

  const [openModalEmail, setOpenModalEmail] = useState(false);

  const deplegarPDF = (docDigital) => {
    setNombreDocumento(docDigital);
    setOpen(true);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const respuestaListaAutorizaciones = await consultarEstadosCuenta(
          cabenceraTransaccional.idCuenta
        );

        /* console.log(respuestaListaAutorizaciones);
         */
        if (
          respuestaListaAutorizaciones.state &&
          respuestaListaAutorizaciones.data
        ) {
          setListaAutorizaciones((args) => ({
            ...args,
            header: respuestaListaAutorizaciones.data.cabecera,
            data: respuestaListaAutorizaciones.data.valorEstados,
          }));
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    getData();
  }, []);

  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        width="100%"
        height={alturaResolucion}
        sx={{ borderRadius: "32px" }}
      />
    );
  }

  if (cabenceraTransaccional.idCuenta <= 0) {
    return (
      <DetalleOpcionGenerica mensaje={"No existe información del cliente."} />
    );
  }

  const notificarEmailEstados = async (valorEstado) => {
    setLoadingNotificar(true);

    if (!cabenceraTransaccional.email) {
      setLoadingNotificar(false);
      modalDispatch({
        type: "SHOW_WARNING",
        title: "ESTADOS CUENTA",
        message: "Error|01: No se ha podido enviar el estado de cuenta.",
      });
      return;
    }

    if (valorEstado.digital === "") {
      setLoadingNotificar(false);
      //alert("Error: No se ha podido enviar el estado de cuenta.");
      modalDispatch({
        type: "SHOW_WARNING",
        title: "ESTADOS CUENTA",
        message: "Error|02: No se ha podido enviar el estado de cuenta.",
      });

      return;
    }

    try {
      const respuestaDocumento = await consultarPDFEstado(valorEstado.digital);

      if (!respuestaDocumento.state) {
        setLoadingNotificar(false);
        modalDispatch({
          type: "SHOW_WARNING",
          title: "ESTADOS CUENTA",
          message: "Error|03: No se ha podido enviar el estado de cuenta.",
        });

        return;
      }

      if (respuestaDocumento.data === null) {
        setLoadingNotificar(false);

        modalDispatch({
          type: "SHOW_WARNING",
          title: "ESTADOS CUENTA",
          message: "Error|04: No se ha podido enviar el estado de cuenta.",
        });

        return;
      }

      /**************************** */

      const informacionData = `${cabenceraTransaccional.nombreCliente}|${valorEstado.fechaCorte}|${valorEstado.fechaVcto}|${valorEstado.valorXPagar}`;

      const estadosCuentaEmail = new EmailEstructura(
        datosBusqueda.idGrupo,
        "ESTCTA",
        "SENDGRID",
        "EMAIL",
        informacionData,
        cabenceraTransaccional.email,
        cabenceraTransaccional.idCuenta
      );

      /* Obtener JSON que devuelve la clase  
    const infoStructura = estadosCuentaEmail.getNotificacion();
    console.log({ infoStructura }); */

      estadosCuentaEmail.addArchivoAdjunto(
        `${valorEstado.digital}`,
        respuestaDocumento.data
      );
      /*  estadosCuentaEmail.addArchivoAdjunto(
        `${valorEstado.digital}`,
        documentoTest
      ); */

      try {
        const respSendEmail = await estadosCuentaEmail.sendEmail();

        /* console.log(respSendEmail); */

        if (respSendEmail.state) {
          if (!respSendEmail.data) {
            modalDispatch({
              type: "SHOW_WARNING",
              title: "ESTADOS CUENTA",
              message: "Error|04: No se ha podido enviar el estado de cuenta.",
            });
            return;
          }
          modalDispatch({
            type: "SHOW_SUCCESS",
            title: "ESTADOS CUENTA",
            message: `Se ha enviado el estado de cuenta al correo: ${cabenceraTransaccional.email}`,
            urlRoute: true,
          });
        } else {
          modalDispatch({
            type: "SHOW_WARNING",
            title: "ESTADOS CUENTA",
            message: "Error|04: No se ha podido enviar el estado de cuenta.",
          });
        }
      } catch (error) {
        console.log(error);
        modalDispatch({
          type: "SHOW_WARNING",
          title: "ESTADOS CUENTA",
          message: "Error|04: No se ha podido enviar el estado de cuenta.",
        });
      } finally {
        setLoadingNotificar(false);
      }
    } catch (error) {
      console.log(error);
      modalDispatch({
        type: "SHOW_WARNING",
        title: "ESTADOS CUENTA",
        message: "Error|04: No se ha podido enviar el estado de cuenta.",
      });
    } finally {
      setLoadingNotificar(false);
    }
  };

  const validarTipoUsuario = () => {
    if (validarUsuario) {
      const columnsData = [
        /* { id: 1, name: "N°" }, */
        { id: 2, name: "F. Corte" },
        { id: 2, name: "F. Vencimiento" },
        { id: 6, name: "F. Pago" },
        { id: 8, name: "Días Vencido" },
        { id: 3, name: "Valor Gestion" },
        { id: 3, name: "Valor Estado" },
        { id: 4, name: "Valor Pagado" },
        { id: 7, name: "Valor Por Pagar" },
        { id: 9, name: "Detalle" },
        { id: 10, name: "Email" },
      ];

      return columnsData;
    } else {
      const columnsDataCliente = [
        /* { id: 1, name: "N°" }, */
        { id: 2, name: "F. Corte" },
        { id: 2, name: "F. Vencimiento" },
        { id: 6, name: "F. Pago" },
        { id: 8, name: "Días Vencido" },
        { id: 3, name: "Valor Gestion" },
        { id: 3, name: "Valor Estado" },
        { id: 4, name: "Valor Pagado" },
        { id: 7, name: "Valor Por Pagar" },
        { id: 9, name: "Detalle" },
      ];
      return columnsDataCliente;
    }
  };

  const validarDocumento = (autorizacionDoc) => {
    /* console.log(autorizacionDoc); */

    if (autorizacionDoc.digital === "" || autorizacionDoc.digital === null) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <SCSTableData
        columnsData={validarTipoUsuario()}
        heightTable={alturaResolucion}
      >
        {listaAutorizaciones.data?.map((autorizacion, index) => (
          <SCSTableDataRow key={autorizacion.idEstadoCuenta}>
            <SCSTableDataCell align="center">
              {autorizacion.fechaCorte}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">
              {autorizacion.fechaVcto}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">
              {autorizacion.fechaPago}
            </SCSTableDataCell>

            <SCSTableDataCell align="center">
              {autorizacion.diasVcdo}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">{`$ ${autorizacion.valorGestion}`}</SCSTableDataCell>

            <SCSTableDataCell align="center">{`$ ${autorizacion.valorEstado}`}</SCSTableDataCell>

            <SCSTableDataCell align="center">{`$ ${autorizacion.valorPagado}`}</SCSTableDataCell>

            <SCSTableDataCell align="center">{`$ ${autorizacion.valorXPagar}`}</SCSTableDataCell>

            <SCSTableDataCell align="center" style={{ padding: "0.5rem" }}>
              <SCSGenericButton
                disabled={validarDocumento(autorizacion)}
                size={100}
                titulo="Ver"
                onClick={() => deplegarPDF(autorizacion.digital)}
              />
            </SCSTableDataCell>
            {validarUsuario && (
              <SCSTableDataCell align="center" style={{ padding: "0.5rem" }}>
                <SCSLoadingButton
                  disabled={validarDocumento(autorizacion)}
                  titulo="Email"
                  loading={loadingNotificar}
                  size={100}
                  onClick={() => notificarEmailEstados(autorizacion)}
                />
              </SCSTableDataCell>
            )}
          </SCSTableDataRow>
        ))}
      </SCSTableData>

      <VisualizarDocumento
        setOpen={setOpen}
        open={open}
        nombreDocumento={nombreDocumento}
      />

      <ModalEstadoProceso
        titulo="Email"
        mensaje={`Se ha enviado el estado de cuenta al correo: ${cabenceraTransaccional.email}`}
        open={openModalEmail}
        setOpen={setOpenModalEmail}
      />

      <ModalHandleNotification
        parameters={modalState}
        setOpen={() => modalDispatch({ type: "CLOSE_MODAL" })}
      />
    </>
  );
};

export default DetalleValoresPorPagar;
