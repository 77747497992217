import React, { useEffect, useState } from "react";

import { Skeleton } from "@mui/material";

import {
  SCSTableData,
  SCSTableDataRow,
  SCSTableDataCell,
} from "app/modules/common/components/TableData/SCSTableData";

import { consultarConsumosCredito } from "../services/transaccionalService";
import DetalleOpcionGenerica from "./DetalleOpcionGenerica";

import { SCSGenericButton } from "app/modules/common/components/Buttons/SCSButtons";
import VisualizarTablaAmortizacion from "../components/VisualizarTablaAmortizacion";

const DetalleEstadoCredito = ({
  cabenceraTransaccional,
  datosBusqueda,
  alturaResolucion,
}) => {
  const [estadosCredito, setEstadosCredito] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [modalAmortizacion, setModalAmortizacion] = useState(false);
  const [autorizacionPDF, setAutorizacionPDF] = useState("");

  useEffect(() => {
    const getData = async () => {
      try {
        const respuestaEstadosCredito = await consultarConsumosCredito(
          datosBusqueda.identificacion,
          datosBusqueda.idGrupo
        );

        /*  console.log(respuestaEstadosCredito); */

        if (respuestaEstadosCredito.state && respuestaEstadosCredito.data) {
          setEstadosCredito(respuestaEstadosCredito.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    getData();
  }, []);

  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        width="100%"
        height={alturaResolucion}
        sx={{ borderRadius: "32px" }}
      />
    );
  }

  if (estadosCredito.cuenta === null) {
    return (
      <DetalleOpcionGenerica mensaje={"No existe información del cliente."} />
    );
  }

  const columnsData = [
    { id: 1, name: "N° Transacción" },
    { id: 2, name: "Consumo" },
    { id: 3, name: "Fecha Compra" },
    { id: 4, name: "Monto" },
    { id: 5, name: "Primer Vcto" },
    { id: 6, name: "Saldo" },
    { id: 7, name: "Valor Cuota" },
    { id: 8, name: "N° Cuota" },
    { id: 9, name: "Amortización" },
  ];

  const handleAmortizacion = (autorizacion) => {
    setModalAmortizacion(true);
    setAutorizacionPDF(autorizacion);
  };

  return (
    <>
      <SCSTableData columnsData={columnsData} heightTable={alturaResolucion}>
        {estadosCredito?.map((consumo) => (
          <SCSTableDataRow key={consumo.idAutorizacion}>
            <SCSTableDataCell align="center">
              <div
                className={`${
                  consumo.amortizacion
                    ? "movimientoCliente-consumos-estado-activa"
                    : "movimientoCliente-consumos-estado-noactiva"
                }`}
              >
                {consumo.numeroAutorizacion}
              </div>
            </SCSTableDataCell>
            <SCSTableDataCell align="center">
              {consumo.consumo}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">
              {consumo.fechaCompra}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">
              {`$ ${consumo.monto}`}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">
              {consumo.fechaPrimerVcto}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">
              {`$ ${consumo.saldo}`}
            </SCSTableDataCell>

            <SCSTableDataCell align="center">
              {`$ ${consumo.valorCuota}`}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">
              {consumo.numCuota}
            </SCSTableDataCell>

            <SCSTableDataCell align="center">
              <SCSGenericButton
                disabled={!consumo.amortizacion}
                size={100}
                titulo="VISUALIZAR"
                onClick={() => handleAmortizacion(consumo.idAutorizacion)}
              />
            </SCSTableDataCell>
          </SCSTableDataRow>
        ))}
      </SCSTableData>

      <VisualizarTablaAmortizacion
        open={modalAmortizacion}
        setOpen={setModalAmortizacion}
        autorizacion={autorizacionPDF}
      />
    </>
  );
};

export default DetalleEstadoCredito;
