import React, { memo, useState, useEffect } from "react";
import { Routes, Route, useHistory } from "react-router-dom";

import Box from "@mui/material/Box";
import { useSelector } from "react-redux";

import {
  consultaSolicitudesUsuario,
} from "../../services/creditoServices";

import "../../styles/main.css";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";

function ConsultarSolicitudesContent() {
  const { authUser } = useSelector(({ auth }) => auth);
  const [solicitudes, setSolicitudes] = useState();
  const [resumen, setResumen] = useState();

  useEffect(() => {
    getInformacion();
  }, []);

  const getInformacion = async () => {
    try {
      var resp = await consultaSolicitudesUsuario(authUser.key);
      if (resp.state) {
        setSolicitudes(resp.data.solicitudes);
        setResumen(resp.data.resumen);
      }
    } catch (e) {
      alert(e.message);
    }
  };


  const formatearFecha = (fecha) => {
    if (!fecha) {
      return "";
    }
    const fechaModificada = fecha.split("T");
    return fechaModificada[0];
  };

  const columnsSolicitudes = [
    {
      field: "identificacion",
      headerName: "Identificacion",
      width: 100,
      headerClassName: "header-grid-solicitud",
      editable: false,
    },
    {
      field: "nombreCliente",
      headerName: "Nombre",
      headerClassName: "header-grid-solicitud",
      width: 290,
      editable: false,
    },
    {
      field: "numeroSolicitud",
      headerName: "Solicitud",
      headerClassName: "header-grid-solicitud",
      width: 147,
      editable: false,
    },
    {
      field: "estado",
      headerName: "Estado",
      headerClassName: "header-grid-solicitud",
      width: 200,
      editable: false,
    },
    {
      field: "montoSolicitado",
      headerName: "Monto",
      headerClassName: "header-grid-solicitud",
      width: 70,
      editable: false,
      valueFormatter: (params) => {
        return `$ ${params.value}`;
      }
    },
    {
      field: "fechaSolicitud",
      headerName: "Fecha",
      headerClassName: "header-grid-solicitud",
      width: 100,
      editable: false,
    },
    {
      field: "aumentoCupo",
      headerName: "Aumento de monto",
      headerClassName: "header-grid-solicitud",
      width: 90,
      editable: false,
    },
    {
      field: "observacion",
      headerName: "Observacion",
      headerClassName: "header-grid-solicitud",
      width: 180,
      editable: false,
    },
  ];

  const rowSolicitudes = solicitudes;

  const columnsResumen = [
    {
      field: "fechaSolicitud",
      headerName: "Fecha ",
      width: 100,
      headerClassName: "header-grid-solicitud",
      editable: false,
    },
    {
      field: "estado",
      headerName: "Estado",
      headerClassName: "header-grid-solicitud",
      width: 290,
      editable: false,
    },
    {
      field: "solicitudes",
      headerName: "Numero Solicitudes",
      headerClassName: "header-grid-solicitud",
      width: 147,
      editable: false,
    },
  ];

  const rowsResumen = resumen;


  return (
    <>
    {rowsResumen ? (
    <div>
      <Box sx={{ height: 200, width: "50%" }}>
      <p>RESUMEN</p>
      <Table size="small" aria-label="simple table">
        <TableHead sx={{ bgcolor: 'text.disabled'}}>
          <TableRow>
            <TableCell align="center"> Fecha</TableCell>
            <TableCell align="center"> Estado</TableCell>
            <TableCell align="center"> Num. Solicitudes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsResumen.map((row) => (
            <TableRow>
              <TableCell align="center" >
                {row.fechaSolicitud}
              </TableCell>
              <TableCell align="center">{row.estado}</TableCell>
              <TableCell align="center">{row.solicitudes}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </Box>
      <Box sx={{ height: 600, width: "100%" }}>
      <p>SOLICITUDES</p>
      <Table size="small" aria-label="simple table" >
        <TableHead sx={{ bgcolor: 'text.disabled', }}>
          <TableRow >
            <TableCell sx={{fontWeight: 'bold'}} align="center"> Identificacion</TableCell>
            <TableCell sx={{fontWeight: 'bold'}} align="center"> Nombre</TableCell>
            <TableCell sx={{fontWeight: 'bold'}} align="center">Solicitud</TableCell>
            <TableCell sx={{fontWeight: 'bold'}} align="center">Estado</TableCell>
            <TableCell sx={{fontWeight: 'bold'}} align="center">Monto Solicitado</TableCell>
            <TableCell sx={{fontWeight: 'bold'}} align="center">Monto Aprobado</TableCell>
            <TableCell sx={{fontWeight: 'bold'}} align="center">Fecha</TableCell>
            <TableCell sx={{fontWeight: 'bold'}} align="center">Aumento de monto</TableCell>
            <TableCell sx={{fontWeight: 'bold'}} align="center">Observacion</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowSolicitudes.map((row) => (
            <TableRow>
              <TableCell align="center" >
                {row.identificacion}
              </TableCell>
              <TableCell align="center">{row.nombreCliente}</TableCell>
              <TableCell align="center">{row.numeroSolicitud}</TableCell>
              <TableCell align="center">{row.estado}</TableCell>
              <TableCell align="center">{row.montoSolicitado}</TableCell>
              <TableCell align="center">{row.montoAprobado}</TableCell>
              <TableCell align="center">{row.fechaSolicitud}</TableCell>
              <TableCell align="center">{row.aumentoCupo}</TableCell>
              <TableCell align="center">{row.observacion}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </Box>
      {/* <Box sx={{ height: 400, width: "100%" }}>

        <DataGrid
          getRowId={(row) => row.identificacion}
          rows={rowSolicitudes}
          columns={columnsSolicitudes}
          pageSize={10}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          rowHeight={40}
        />
      </Box> */}
    </div>
    ) : (
      <SpinnerLoadSrx />
    )}
    </>
  );
}

export default memo(ConsultarSolicitudesContent);
