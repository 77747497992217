import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { useMediaQuery } from "@mui/material";

import TransaccionesCliente from "../TransaccionesCliente/TransaccionesCliente";
import DetalleTransaccionalMobile from "../../containers/DetalleTransaccionalMobile";
import DetalleTransaccional from "../../containers/DetalleTransaccional";

const TransaccionesInformacionCliente = () => {
  const history = useHistory();

  const [datosBusqueda, setDatosBusqueda] = useState({
    identificacion: history?.location?.state?.state?.identificacion || "",
    mostrarBuscador: true,
    idMovimiento: 0,
    idGrupo: Number(localStorage.getItem("idGrupoStorage")),
    menuMobile: true,
  });

  const [identificacionCliente, setIdentificacionCliente] = useState(
    history?.location?.state?.state?.identificacion
  );

  const transactionModeMobile = useMediaQuery("(max-width:700px)");

  console.log(datosBusqueda);

  useEffect(() => {
    setDatosBusqueda((args) => ({
      ...args,
      identificacion: identificacionCliente,
      mostrarBuscador: false,
      idMovimiento: 0,
      idGrupo: Number(localStorage.getItem("idGrupoStorage")),
      menuMobile: true,
    }));
  }, []);

  if (
    datosBusqueda.identificacion !== "" &&
    history?.location?.state?.state?.identificacion
  ) {
    return (
      <>
        {transactionModeMobile ? (
          <DetalleTransaccionalMobile
            datosBusqueda={datosBusqueda}
            setDatosBusqueda={setDatosBusqueda}
          />
        ) : (
          <DetalleTransaccional
            datosBusqueda={datosBusqueda}
            setDatosBusqueda={setDatosBusqueda}
          />
        )}
      </>
    );
  }

  return (
    <>
      <TransaccionesCliente />
    </>
  );
};

export default TransaccionesInformacionCliente;
