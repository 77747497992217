import React, { useState, useEffect } from "react";

import { consultarEstadoCredito } from "../services/transaccionalService";
import { Paper, Skeleton } from "@mui/material";

const CabeceraCuentaCredito = ({ datosBusqueda }) => {
  const [cabeceraConsumo, setCabeceraConsumo] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getCabeceraEstado = async () => {
      try {
        const respuestaCabeceraConsumo = await consultarEstadoCredito(
          datosBusqueda.identificacion,
          datosBusqueda.idGrupo
        );

        console.log(respuestaCabeceraConsumo);

        debugger;

        if (respuestaCabeceraConsumo.state) {
          setCabeceraConsumo(respuestaCabeceraConsumo.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    getCabeceraEstado();
  }, []);

  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        sx={{
          borderRadius: "25px",
          margin: "0",
          padding: "0",
          width: "100%",
          minHeight: "10vh",
          maxHeight: "auto",
        }}
      />
    );
  }

  const rectificarValor = (valor) => {
    /*  console.log(valor); */

    if (Number(valor) !== 0.0) {
      if (Number(valor) < 0) {
        return "$ 0.0";
      }

      return `$ ${valor}`;
    } else {
      return "$ 0.0";
    }
  };

  return (
    <Paper
      elevation={1}
      sx={{ /* minHeight: "10vh",  maxHeight: "auto",*/ borderRadius: "25px" }}
    >
      <section className="header-cuenta-information">
        <div class="header-cuenta-detalle-header">
          <div class="item11" /*  style={{ paddingLeft: "1.5rem" }} */>
            <h4>
              <strong>Identificación </strong>
            </h4>
          </div>
          <div class="item12">
            <h4>
              {cabeceraConsumo.identificacion
                ? `${cabeceraConsumo.identificacion}`
                : ""}
            </h4>
          </div>
          <div class="item13">
            <h4>
              <strong>Nombre </strong>
            </h4>
          </div>
          <div class="item14">
            <h4>
              {cabeceraConsumo.nombreCliente
                ? `${cabeceraConsumo.nombreCliente}`
                : ""}
            </h4>
          </div>
          <div class="item15">
            <h4>
              <strong>Grupo </strong>
            </h4>
          </div>
          <div class="item16">
            <h4>{cabeceraConsumo.grupo ? `${cabeceraConsumo.grupo}` : ""}</h4>
          </div>
        </div>

        {/* INFORMACION CUENTA */}
        <div class="header-cuenta-valores">
          <div class="item29">
            <h4>
              <strong>Colaborador: </strong>
            </h4>
          </div>

          <div class="item30">
            <h4>{cabeceraConsumo?.colaborador ? `SI` : "NO"}</h4>
          </div>

          <div class="item7">
            <h4>
              <strong>Bloqueo: </strong>
            </h4>
          </div>
          <div class="item7">
            <h4>{cabeceraConsumo?.bloqueo ? `SI` : "NO"}</h4>
          </div>

          <div class="item11">
            <h4>
              <strong>Valor Estado: </strong>
            </h4>
          </div>

          <div class="item12">
            <h4>
              {cabeceraConsumo?.valorEstado
                ? ` $ ${cabeceraConsumo?.valorEstado}`
                : "$ 0.0"}
            </h4>
          </div>

          <div class="item13">
            <h4>
              <strong>Valor a Pagar: </strong>
            </h4>
          </div>

          <div class="item14">
            <h4>
              {cabeceraConsumo?.valorPagar
                ? ` $ ${cabeceraConsumo?.valorPagar}`
                : "$ 0.0"}
            </h4>
          </div>

          <div class="item31">
            <h4>
              <strong>Credito: </strong>
            </h4>
          </div>

          <div class="item31">
            <h4>
              {cabeceraConsumo?.estadoCredito
                ? ` ${cabeceraConsumo?.estadoCredito}`
                : ""}
            </h4>
          </div>

          <div class="item7">
            <h4>
              <strong>Fecha Emisión: </strong>
            </h4>
          </div>

          <div class="item8">
            <h4>
              {cabeceraConsumo?.fechaCorte
                ? ` ${cabeceraConsumo?.fechaCorte}`
                : ""}
            </h4>
          </div>

          <div class="item9">
            <h4>
              <strong>Fecha Máx. Pago: </strong>
            </h4>
          </div>

          <div class="item10">
            <h4>
              {cabeceraConsumo?.fechaPago
                ? ` ${cabeceraConsumo?.fechaPago}`
                : ""}
            </h4>
          </div>

          <div class="item2">
            <h4>
              <strong>Aprobado MG: </strong>
            </h4>
          </div>

          <div class="item2">
            <h4>
              {cabeceraConsumo?.aprobado
                ? ` $ ${cabeceraConsumo?.aprobado}`
                : "$ 0.0"}
            </h4>
          </div>

          <div class="item5">
            <h4>
              <strong>Disponible MG: </strong>
            </h4>
          </div>

          <div class="item6">
            <h4>{rectificarValor(cabeceraConsumo?.disponible)}</h4>
          </div>

          <div class="item21">
            <h4>
              <strong>Cartera: </strong>
            </h4>
          </div>

          <div class="item22">
            <h4>
              {cabeceraConsumo?.cartera ? `${cabeceraConsumo?.cartera}` : ""}
            </h4>
          </div>
        </div>

        <div className="header-cuenta-lastRow">
          <div class="item17">
            <h4>
              <strong>Estados Vencidos: </strong>
            </h4>
          </div>
          <div class="item18">
            <h4>
              {cabeceraConsumo?.estadosVencidos ??
                ` ${cabeceraConsumo?.estadosVencidos}`}
            </h4>
          </div>

          <div class="item19">
            <h4>
              <strong>Día Corte: </strong>
            </h4>
          </div>

          <div class="item20">
            <h4>
              {cabeceraConsumo?.periodo ? ` ${cabeceraConsumo?.periodo}` : ""}
            </h4>
          </div>

          {Number(cabeceraConsumo.aprobado2) !== 0.0 && (
            <>
              <div class="item23">
                <h4>
                  <strong>Aprobado Abastos: </strong>
                </h4>
              </div>
              <div class="item24">
                <h4>
                  {cabeceraConsumo?.aprobado2
                    ? `$ ${cabeceraConsumo?.aprobado2}`
                    : "$ 0.0"}
                </h4>
              </div>

              <div class="item27">
                <h4>
                  <strong>Disponible Abastos: </strong>
                </h4>
              </div>
              <div class="item28">
                <h4>{rectificarValor(cabeceraConsumo?.disponible2)}</h4>
              </div>
            </>
          )}

          {Number(cabeceraConsumo.aprobado2) !== 0.0 ? (
            <>
              <div class="item15">
                <h4>
                  <strong>Cuenta: </strong>
                </h4>
              </div>
            </>
          ) : (
            <>
              <div class="item7">
                {/* <h4>
            <strong>Fecha Emisión: </strong>
          </h4> */}
              </div>
              <div class="item7">
                {/* <h4>
            <strong>Fecha Emisión: </strong>
          </h4> */}
              </div>
              <div class="item7">
                {/* <h4>
            <strong>Fecha Emisión: </strong>
          </h4> */}
              </div>
              <div class="item7">
                {/* <h4>
            <strong>Fecha Emisión: </strong>
          </h4> */}
              </div>
              <div class="item15">
                <h4>
                  <strong>Cuenta: </strong>
                </h4>
              </div>
            </>
          )}

          <div class="item16">
            <h4>
              {cabeceraConsumo?.estadoCuenta
                ? `${cabeceraConsumo?.estadoCuenta}`
                : ""}
            </h4>
          </div>
        </div>
      </section>
    </Paper>
  );
};

export default CabeceraCuentaCredito;
