import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";

import FormSolicitudAumentoCupo from "../components/FormSolicitudAumentoCupo";
import FormInformacionCliente from "../../app-cliente/components/FormInformacionCliente";
import FormInformacionUbicabilidad from "../../app-cliente/components/FormInformacionUbicabilidad";

import "../styles/main.css";
import FormClienteActividadEconomica from "app/modules/app-cliente/components/FormClienteActividadEconomica";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabAumentoCupo = ({ identificacion, idCli, clienteConsultado }) => {
  const [value, setValue] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [idCliente, setIdCliente] = useState();
  const [cliente, setCliente] = useState();
  const [idSolicitud, setIdSolicitud] = useState(-1);
  const [actividadEconomica, setActividadEconomica] = useState(null);
  const [validarConyugue, setValidarConyugue] = useState(false);

  const [idClienteInformacion, setIdClienteInformacion] = useState(-1);
  const [idClienteAux, setIdClienteAux] = useState(null);
  // let cliente ={}
  // let idCliente =-1

  useEffect(() => {
    setIdClienteAux(idCli);
  }, []);

  useEffect(() => {
    console.log(idClienteInformacion);

    setIdClienteInformacion(idClienteAux);
    if (idClienteInformacion > 0) {
      setIdClienteInformacion(idClienteInformacion);
      setIdClienteAux(idClienteInformacion);
    }
  }, [idClienteInformacion]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="form-container-info">
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }} marginTop="30px">
          <Tabs
            centered
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            textColor="success"
          >
            <Tab
              label="Informacion Cliente"
              {...a11yProps(0)}
              className="tabsAnalista"
            />
            <Tab
              label="Informacion Financiera"
              {...a11yProps(1)}
              className="tabsAnalista"
            />
            <Tab
              label="Informacion Solicitud"
              {...a11yProps(2)}
              className="tabsAnalista"
            />
            {/* <Tab
              label="Firma electrónica"
              {...a11yProps(2)}
              className="tabsAnalista"
            /> */}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {idClienteAux && (
            <div>
              <FormInformacionCliente
                idCliente={idClienteAux}
                identificacion={identificacion}
                setIdClienteInformacion={setIdClienteInformacion}
                setValidarConyugue={setValidarConyugue}
              />
              <FormInformacionUbicabilidad idCliente={idClienteAux} />
            </div>
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <FormClienteActividadEconomica
            idCliente={idClienteAux}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <FormSolicitudAumentoCupo
            idCli={idClienteInformacion}
            cliente={clienteConsultado}
            identifCliente={identificacion}
            idSolicitudChanger={setIdSolicitud}
          />
        </TabPanel>
        {/* <TabPanel value={value} index={2}>
          <FormFirmaDigital
            idCli={idClienteInformacion}
            cliente={clienteConsultado}
            identifCliente={identificacion}
            idSolicitudChanger={setIdSolicitud}
          />
        </TabPanel> */}
      </Box>
    </div>
  );
};

export default TabAumentoCupo;
